import { METHOD } from "@cuatroochenta/co-generic-request";
import classNames from "classnames";
import React, { Component, ReactNode } from "react";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { WrappedFieldProps } from "redux-form";
import Alert from "../../base/alerts/Alert";
import I18n from "../../commons/I18n/I18n";
import Urls from "../../commons/ws/Urls";
import Config, { AppIcon } from "../../config/Config";
import I18nKeys from "../../I18n/I18nKeys";
import AuthManager from "../../utils/AuthManager";
import FileInfo from "../FileInfo";
import FileProgress from "../FileProgress";
import Icon from "../Icon";
import FormCol, { FormColProps } from "./FormCol";

export interface FormDragFileProps extends WrappedFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  col: FormColProps;
  showError?: boolean;
  maxMBSize?: number;
  multiple?: boolean;
  fileTypes?: string[];
  fileIcon?: string;
  noClick?: boolean;
  children?: ReactNode;
  isDragActionStart?: boolean;
  openFileDialog?: boolean;
  closeFileDialogHandler?: ()=> void;
  invalidFileMessage?: string;
  finishDragActionStartHandler?: ()=> void;
}

interface DropZoneFile extends File {
  path: string;
}

interface State {
  uploadingFiles: DropZoneFile[];
  touched: boolean;
  dropZoneVisible: boolean;

  [fileIndex: number]: number;
}

export default class ChatFormDragFile extends Component<
  FormDragFileProps,
  State
> {
  public state: State = {
    uploadingFiles: [],
    touched: false,

    dropZoneVisible: false,
  };

  private requests: XMLHttpRequest[] = [];

  private dropZoneRef = React.createRef<DropzoneRef>();

  public componentWillUnmount(): void {
    this.requests.forEach((request) => request.abort());
  }

  public componentDidUpdate(): void {
    if (this.props.openFileDialog) 
      this.onOpenFileDialog();
  }

  public render(): ReactNode {
    const {meta,
      label,
      col,
      showError,
      input: { value },
      children,
      isDragActionStart} = this.props;
    const hasFiles =
      this.isUploadingFile() || (Array.isArray(value) && value.length !== 0);

    return (
      <FormCol {...col}>
        <div className={"form-group"}>
          {label ? <label className={"main-label"}>{label || ""}</label> : null}

          {!hasFiles && !isDragActionStart && children}
          {this.renderDropZone(hasFiles)}

          <label className="error">
            {this.state.touched || showError ? meta.error : ""}
          </label>
        </div>
      </FormCol>
    );
  }

  private onOpenFileDialog = (): void => {
    if (
      this.dropZoneRef &&
      this.dropZoneRef.current &&
      this.dropZoneRef.current.open != null
    ) {
      // @ts-ignore
      this.dropZoneRef.current.open();
    }
  };

  private sendFile = (file: DropZoneFile, index: number): void => {
    const {input: { onChange },
      multiple} = this.props;
    const data = new FormData();

    data.append("file", file, file.name);

    const request = this.requests[index];

    request.upload.onprogress = (event) => {
      const percent = +((event.loaded / event.total) * 100).toFixed(2);

      this.setState( {
        [index]: percent,
      } );
    };
    request.onreadystatechange = () => {
      if (request.readyState !== 4) 
        return;

      if (request.status !== 0) {
        const response = JSON.parse(request.response);

        if (response.success) {
          // importante que lea el value desde esta línea!!!
          onChange(
            multiple
              ? [response.data.url, ...this.props.input.value]
              : [response.data.url],
          );
          Alert.success(I18n.tr(I18nKeys.FICHERO_CARGADO_CORRECTAMENTE));
        } else 
          Alert.error(response.message);
      }
    };

    request.open(METHOD.POST, Urls.URL_FILE_UPLOAD);

    if (AuthManager.isLogged())
    {request.setRequestHeader(
      "Authorization",
      `Bearer ${AuthManager.getAuthToken()}`,
    );}

    request.setRequestHeader("Accept", "application/json");
    request.send(data);
  };

  private onDrop = (acceptedFiles: DropZoneFile[]): void => {
    const newState: State = {
      uploadingFiles: [],
      touched: false,
      dropZoneVisible: false,
    };

    // resetea el array de peticiones ajax
    this.requests.length = 0;

    // iniciamos el estado individual antes de la carga
    acceptedFiles.forEach((file, index) => {
      this.requests.push(new XMLHttpRequest());
      newState.uploadingFiles.push(file);
      newState[index] = 0;
    } );

    this.setState(newState);

    acceptedFiles.forEach(this.sendFile);
  };

  private onRemoveFile = (toRemoveUrl: string): void => {
    const {input: { onChange, value }} = this.props;

    if (Array.isArray(value))
      onChange(value.filter((url) => url !== toRemoveUrl));

    this.setState( {
      touched: true,
    } );
  };

  private isUploadingFile = (): boolean =>
    !!this.requests.find((request) => request.readyState !== 4);

  private renderDropZone = (hasFiles: boolean): ReactNode => {
    const {disabled,
      maxMBSize = Config.MAX_FILE_SIZE_MB,
      fileTypes = [],
      multiple = false,
      noClick = false,
      className,
      isDragActionStart,
      children,
      closeFileDialogHandler,
      invalidFileMessage,
      finishDragActionStartHandler} = this.props;
    const maxSize = maxMBSize * 1024 * 1024;
    const classNameDzRoot = `dz-root ${children && "big-zone"}`;
    const visible = !hasFiles && !isDragActionStart ? "not-visible" : "visible";

    return (
      <span className={`dropzone-visibility ${visible}`}>
        {/*
            // @ts-ignore */}
        <Dropzone
          ref={this.dropZoneRef}
          // @ts-ignore
          onDrop={this.onDrop}
          disabled={disabled || this.isUploadingFile()}
          maxSize={maxSize}
          multiple={multiple}
          accept={fileTypes.join(",")}
          onFileDialogCancel={() => {
            if (closeFileDialogHandler) 
              closeFileDialogHandler();

            this.setState( {
              touched: true,
            } );
          }}
          noClick={noClick}
          onDropAccepted={() => {
            if (closeFileDialogHandler) 
              closeFileDialogHandler();
          }}
          onDropRejected={(files) => {
            if (!multiple && files.length > 1)
              Alert.error(I18n.tr(I18nKeys.SOLO_ADMITE_UN_FICHERO));
            else {
              let existInvalidExtension: boolean = false;

              files.forEach((file) => {
                const fileExtension = file.type.split("/")[1];

                existInvalidExtension = !fileTypes.includes(fileExtension);
              } );

              if (existInvalidExtension)
              {Alert.error(
                I18n.tr(
                  invalidFileMessage || I18nKeys.TIPO_DE_FICHERO_NO_VALIDO,
                ),
              );}
            }

            if (finishDragActionStartHandler) 
              finishDragActionStartHandler();
          }}
        >
          {( { getRootProps, getInputProps } ) => (
            <div
              className={classNames(`dropzone ${className}`, {
                disabled,
              } )}
            >
              <div {...getRootProps()} className={classNameDzRoot}>
                <input {...getInputProps()} />
                {hasFiles && !isDragActionStart
                  ? this.renderFiles()
                  : this.renderDZMessage()}
              </div>
            </div>
          )}
        </Dropzone>
      </span>
    );
  };

  private renderDZMessage = (): ReactNode => {
    const {fileIcon = AppIcon.DOCUMENTATION,
      multiple = false,
      isDragActionStart} = this.props;
    const className = `dz-message ${isDragActionStart && "dz-message-border"}`;

    return (
      <div className={className}>
        <Icon icon={fileIcon} />
        <p>
          {I18n.tr(
            multiple
              ? I18nKeys.ARRASTRA_LOS_ARCHIVOS_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS
              : I18nKeys.ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO,
          )}
        </p>
      </div>
    );
  };

  private renderFiles = (): ReactNode => {
    const {input: { value },
      multiple = false,
      fileIcon = AppIcon.DOCUMENTATION,
      children} = this.props;
    const { uploadingFiles } = this.state;
    const className = `dz-files ${value && value.length !== 0 && "m-t--20"}`;

    return (
      <div className={className}>
        {this.requests.map(
          (request, index) =>
            request.readyState !== 4 && (
              <FileProgress
                key={index}
                icon={fileIcon}
                cancelHandler={() => this.requests[index].abort()}
                percentLoaded={this.state[index] || 0}
                size={uploadingFiles[index].size}
              />
            ),
        )}
        {Array.isArray(value) &&
          value.map((url: string) => (
            <FileInfo
              key={url}
              url={url}
              icon={fileIcon}
              fileName={url.replace(`${Urls.URL_FILE_UPLOAD}s/`, "")}
              removeHandler={() => this.onRemoveFile(url)}
              className={multiple ? "" : "p-l-0 m-t-15"}
              fileInfoInline={!!children}
            />
          ))}
        {children}
      </div>
    );
  };
}
