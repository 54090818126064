import { METHOD } from "@cuatroochenta/co-generic-request";

import BaseGenericRequest from "../BaseGenericRequest";

import TaskResponse from "../TaskResponse";

import Urls from "../Urls";

import { TaskCriteriaRequest } from "../TaskCriteriaRequest";

import Report from "../../model/Report";

export interface ReportCustomCriteria {

    simply?: boolean;

    number?: string;

    start_date?: string;

    end_date?: string;

    vehicle_ids?: string[];

    law_ids?: string[];

    controller_ids?: string[];

    route_ids?: string[];

    ratifier_ids?: string[];

    street_ids?: string[];

    report_state_ids?: string[];

    ratified?: boolean;

    report_sent_to_process_state_ids?: string[];

    notify_crane?: boolean;

    signature_controller?: boolean;

    signature_ratifier?: boolean;

    not_controller_ids?: string[];

    workday_type_ids?: string[];

    cancellation_number?: string;

}

export type ReportCriteriaProps = TaskCriteriaRequest<ReportCustomCriteria>;

export default class TaskReportList extends BaseGenericRequest<ReportCriteriaProps, TaskResponse<Report[]>> {
  private readonly data: ReportCriteriaProps;

  public constructor(criteria: ReportCriteriaProps = {

  } ) {
    super(METHOD.POST, Urls.URL_REPORT_LIST);

    this.data = criteria;
  }

  protected getRequest(): ReportCriteriaProps {
    return this.data;
  }
}
