import React from "react";
import I18n from "../../../../commons/I18n/I18n";
import Law from "../../../../commons/model/Law";
import { generateRoute } from "../../../../commons/utils/Router";
import ActiveLabel from "../../../../components/label/ActiveLabel";
import Th from "../../../../components/table/Th";
import I18nKeys from "../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_LAW_DETAIL } from "../../../../routing/Routes";
import { goToRoute } from "../../../../utils/Router";
import { OnSortHandler, SortState } from "../../../../utils/Sort";

interface LawListTableProps {
  data: Law[];
  error: string;
  onSort: OnSortHandler;
  sort: SortState;
  renderSelectTd?: (itemId: string)=> React.ReactNode;
}

const goToItemDetail = (itemId: string): void => {
  goToRoute(
    generateRoute(ROUTE_COMPANY_USER_LAW_DETAIL, {
      id: itemId,
    } ),
  );
};

export default class LawListTable extends React.Component<LawListTableProps> {
  public render(): React.ReactNode {
    const { data, renderSelectTd } = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data &&
            data.map((law: Law) => (
              <tr
                key={law.id}
                onClick={() => goToItemDetail(law.id)}
                className={"tr-cursor"}
              >
                {renderSelectTd ? renderSelectTd(law.id) : null}
                <td>{law.law_type && law.law_type.name}</td>
                <td>{law.municipal_code}</td>
                <td>{law.amount}</td>
                <td>{law.cancellation_amount}</td>
                <td>
                  <ActiveLabel
                    text={
                      law.cancelable_report
                        ? I18n.tr(I18nKeys.ANULABLE)
                        : I18n.tr(I18nKeys.NO_ANULABLE)
                    }
                    active={law.cancelable_report}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const { sort, children, onSort } = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    return (
      <tr>
        {children}
        <Th
          text={I18n.tr(I18nKeys.TIPO_DE_LEY)}
          columnName={"law_type"}
          {...commonProps}
        />
        <Th
          text={I18n.tr(I18nKeys.CODIGO_MUNICIPAL)}
          columnName={"article_code"}
          {...commonProps}
        />
        <Th
          text={I18n.tr(I18nKeys.IMPORTE)}
          columnName={"amount"}
          {...commonProps}
        />
        <Th
          text={I18n.tr(I18nKeys.IMPORTE_ANULACION)}
          columnName={"cancellation_amount"}
          {...commonProps}
        />
        <Th
          text={I18n.tr(I18nKeys.DENUNCIA_ANULABLE)}
          columnName={"cancelable_report"}
          {...commonProps}
        />
      </tr>
    );
  }
}
