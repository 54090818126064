import React from "react";
import I18n from "../../../../../commons/I18n/I18n";
import ChatBroadcast from "../../../../../commons/model/ChatBroadcast";
import Th from "../../../../../components/table/Th";
import I18nKeys from "../../../../../I18n/I18nKeys";
import DateFormatter from "../../../../../utils/DateFormatter";
import { OnSortHandler, SortState } from "../../../../../utils/Sort";

interface ChatBroadcastListTableProps {
  data: ChatBroadcast[];
  error: string;
  onSort: OnSortHandler;
  sort: SortState;
  renderSelectTd?: (itemId: string)=> React.ReactNode;
}

export default class ChatBroadcastListTable extends React.Component<ChatBroadcastListTableProps> {
  public render(): React.ReactNode {
    const { data, renderSelectTd } = this.props;

    return (
      <table className="table table-hover">
        <thead>{this.renderTH()}</thead>
        <tfoot>{this.renderTH()}</tfoot>
        <tbody>
          {data &&
            data.map((broadcast: ChatBroadcast) => (
              <tr key={broadcast.id}>
                {renderSelectTd ? renderSelectTd(broadcast.id) : null}
                <td>{broadcast.message ? broadcast.message : ""}</td>
                <td>
                  {broadcast.date
                    ? DateFormatter.formatDatetime(broadcast.date)
                    : ""}
                </td>
                <td>
                  {broadcast.expiration_date
                    ? DateFormatter.formatDatetime(broadcast.expiration_date)
                    : ""}
                </td>
                <td>
                  {broadcast.user
                    ? `${broadcast.user.name} ${broadcast.user.last_name}`
                    : ""}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

  private renderTH(): React.ReactNode {
    const { sort, children, onSort } = this.props;
    const commonProps = {
      sort,
      onSort,
    };

    // TODO: realizar ordenación
    return (
      <tr>
        {children}
        <Th
          text={I18n.tr(I18nKeys.MENSAJE)}
          columnName={"message"}
          {...commonProps}
        />
        <Th
          text={I18n.tr(I18nKeys.FECHA)}
          columnName={"date"}
          {...commonProps}
        />
        <Th
          text={I18n.tr(I18nKeys.CADUCIDAD)}
          columnName={"expiration_date"}
          {...commonProps}
        />
        <Th
          text={I18n.tr(I18nKeys.USUARIO)}
          columnName={"user.name"}
          {...commonProps}
        />
      </tr>
    );
  }
}
