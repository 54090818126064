import React from "react";
import { connect } from "react-redux";
import I18n from "../../../../../commons/I18n/I18n";
import TaskChatBroadcastList from "../../../../../commons/ws/chat/TaskChatBroadcastList";
import CardList from "../../../../../components/card/CardList";
import I18nKeys from "../../../../../I18n/I18nKeys";
import { ROUTE_COMPANY_USER_BROADCAST_ADD } from "../../../../../routing/Routes";
import ChatBroadcastCompanyUserActions from "./ChatBroadcastCompanyUserActions";
import ChatBroadcastCompanyUserReducer from "./ChatBroadcastCompanyUserReducer";
import ChatBroadcastListTable from "./ChatBroadcastListTable";

const mapActionsToProps = ChatBroadcastCompanyUserActions.autoMapToProps();
const mapStateToProps = ChatBroadcastCompanyUserReducer.autoMapToProps();

class ChatBroadcastCardCompanyUser extends React.Component<
  typeof mapActionsToProps & typeof mapStateToProps
> {
  public componentWillMount(): void {
    this.props.clearReducer();
  }

  public render(): React.ReactNode {
    const { loading, broadcastList, error, pager } = this.props;

    return (
      <CardList
        loading={loading}
        pager={pager}
        title={I18n.tr(I18nKeys.MENSAJES_A_TODOS)}
        sort={{
          column: "expiration_date",
        }}
        TaskList={TaskChatBroadcastList}
        emptyListOptions={{
          message: I18n.tr(I18nKeys.NO_EXISTEN_MENSAJES),
        }}
        data={broadcastList}
        error={error}
        ItemsTable={ChatBroadcastListTable}
        addItem={ROUTE_COMPANY_USER_BROADCAST_ADD}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ChatBroadcastCardCompanyUser as unknown as React.ComponentType<void>);
