
enum I18nKeys {
  PAVAPARK = "PAVAPARK",
  GENERIC_WS_ERROR = "GENERIC_WS_ERROR",
  ACEPTAR = "ACEPTAR",
  CANCELAR = "CANCELAR",
  CARGANDO = "CARGANDO",
  NOMBRE = "NOMBRE",
  APELLIDO = "APELLIDO",
  APELLIDOS = "APELLIDOS",
  EMAIL = "EMAIL",
  EXPLOTACION = "EXPLOTACION",
  ROL = "ROL",
  ANADIR = "ANADIR",
  NO = "NO",
  SI = "SI",
  GUARDAR = "GUARDAR",
  HOME = "HOME",
  ANADIR_A_FAVORITOS = "ANADIR_A_FAVORITOS",
  QUITAR_DE_FAVORITOS = "QUITAR_DE_FAVORITOS",
  CREAR = "CREAR",
  ELIMINAR = "ELIMINAR",
  ELIMINADOS_CORRECTAMENTE = "ELIMINADOS_CORRECTAMENTE",
  ANADIDOS_A_FAVORITOS_CORRECTAMENTE = "ANADIDOS_A_FAVORITOS_CORRECTAMENTE",
  QUITADOS_DE_FAVORITOS_CORRECTAMENTE = "QUITADOS_DE_FAVORITOS_CORRECTAMENTE",
  ANADIDO_A_FAVORITOS_CORRECTAMENTE = "ANADIDO_A_FAVORITOS_CORRECTAMENTE",
  QUITADO_DE_FAVORITOS_CORRECTAMENTE = "QUITADO_DE_FAVORITOS_CORRECTAMENTE",
  BUSCANDO = "BUSCANDO",
  NO_SE_HAN_ENCONTRADO_RESULTADOS = "NO_SE_HAN_ENCONTRADO_RESULTADOS",
  ROLES = "ROLES",
  DE = "DE",
  NUEVO = "NUEVO",
  VALOR_MAXIMO = "VALOR_MAXIMO",
  VALOR_MINIMO = "VALOR_MINIMO",
  LIMITE_DE_CARACTERES = "LIMITE_DE_CARACTERES",
  CERRAR = "CERRAR",
  FECHA = "FECHA",
  FECHA_RECAUDACION = "FECHA_RECAUDACION",
  FECHA_REGISTRO = "FECHA_REGISTRO",
  FILTROS = "FILTROS",
  NO_ESTAS_AUTORIZADO = "NO_ESTAS_AUTORIZADO",
  CONTRASENA_INCORRECTA = "CONTRASENA_INCORRECTA",
  NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA = "NO_TIENES_AUTORIZACION_PARA_CAMBIAR_LA_CONTRASENA",
  EL_FICHERO_EXCEDE_EL_TAMANO_MAXIMO = "EL_FICHERO_EXCEDE_EL_TAMANO_MAXIMO",
  YA_EXISTE_UN_ELEMENTO_SIMILAR = "YA_EXISTE_UN_ELEMENTO_SIMILAR",
  YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF = "YA_EXISTE_UN_USUARIO_CON_EL_MISMO_CORREO_O_NIF",
  MENU = "MENU",
  PERFIL = "PERFIL",
  SALIR = "SALIR",
  INICIO = "INICIO",
  MOVIMIENTOS = "MOVIMIENTOS",
  NUEVA_ENTRADA = "NUEVA_ENTRADA",
  NUEVA_SALIDA = "NUEVA_SALIDA",
  TRANSPORTISTAS = "TRANSPORTISTAS",
  SESIONES = "SESIONES",
  BUSCAR = "BUSCAR",
  VER_TODAS_LAS_ALERTAS = "VER_TODAS_LAS_ALERTAS",
  CERRAR_SESSION = "CERRAR_SESSION",
  ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION = "ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION",
  IR_A_LA_PAGINA = "IR_A_LA_PAGINA",
  DATOS_DE_AUTENTICACION_INCORRECTOS = "DATOS_DE_AUTENTICACION_INCORRECTOS",
  MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE = "MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE",
  ABRIR_SESION = "ABRIR_SESION",
  EMAIL_O_NOMBRE_DE_USUARIO = "EMAIL_O_NOMBRE_DE_USUARIO",
  CONTRASENA = "CONTRASENA",
  RECUERDAME = "RECUERDAME",
  LOGIN = "LOGIN",
  OLVIDASTE_TU_CONTRASENA = "OLVIDASTE_TU_CONTRASENA",
  RESTABLECE_LA_CONTRASENA = "RESTABLECE_LA_CONTRASENA",
  TE_MANDAREMOS_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA = "TE_MANDAREMOS_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA",
  TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA = "TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA",
  LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA = "LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA",
  USUARIO_NO_ENCONTRADO = "USUARIO_NO_ENCONTRADO",
  INTRODUCE_TU_CONTRASENA = "INTRODUCE_TU_CONTRASENA",
  POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA = "POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA",
  LOS_DATOS_ENVIADOS_NO_SON_CORRECTOS = "LOS_DATOS_ENVIADOS_NO_SON_CORRECTOS",
  USUARIO_BLOQUEADO_NO_SE_PUEDE_HACER_LOGIN_DURANTE_LAS_SIGUIENTES_2_HORAS = "USUARIO_BLOQUEADO_NO_SE_PUEDE_HACER_LOGIN_DURANTE_LAS_SIGUIENTES_2_HORAS",
  EL_EMAIL_NO_ES_VALIDO = "EL_EMAIL_NO_ES_VALIDO",
  URL = "URL",
  CLAVE_ELK = "CLAVE_ELK",
  CLAVE_ELK_OBLIGATORIA = "CLAVE_ELK_OBLIGATORIA",
  LA_URL_NO_ES_VALIDA = "LA_URL_NO_ES_VALIDA",
  LAS_CONTRASENAS_NO_COINCIDEN = "LAS_CONTRASENAS_NO_COINCIDEN",
  ALERTAS = "ALERTAS",
  PENDIENTES = "PENDIENTES",
  FAVORITOS = "FAVORITOS",
  DASHBOARDS = "DASHBOARDS",
  FORMULARIOS = "FORMULARIOS",
  RESULTADO_DE_FORMULARIO = "RESULTADO_DE_FORMULARIO",
  DOCUMENTACION = "DOCUMENTACION",
  ENTIDAD = "ENTIDAD",
  FECHA_OBLIGATORIA = "FECHA_OBLIGATORIA",
  HORA_OBLIGATORIA = "HORA_OBLIGATORIA",
  USUARIOS = "USUARIOS",
  NO_EXISTEN_USUARIOS = "NO_EXISTEN_USUARIOS",
  NUEVO_USUARIO = "NUEVO_USUARIO",
  USUARIO = "USUARIO",
  CREAR_USUARIO = "CREAR_USUARIO",
  USUARIO_CREADO_CORRECTAMENTE = "USUARIO_CREADO_CORRECTAMENTE",
  USUARIO_MODIFICADO_CORRECTAMENTE = "USUARIO_MODIFICADO_CORRECTAMENTE",
  CAMBIAR_CONTRASENA = "CAMBIAR_CONTRASENA",
  RESTABLECER_CONTRASENA = "RESTABLECER_CONTRASENA",
  NUEVA_CONTRASENA = "NUEVA_CONTRASENA",
  REPITE_LA_CONTRASENA = "REPITE_LA_CONTRASENA",
  LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA = "LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA",
  LA_CONTRASENA_TIENE_POCOS_CARACTERES = "LA_CONTRASENA_TIENE_POCOS_CARACTERES",
  LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES = "LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES",
  CONTRASENA_MODIFICADA_CORRECTAMENTE = "CONTRASENA_MODIFICADA_CORRECTAMENTE",
  NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA = "NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA",
  ANADE_AL_MENOS_UN_USUARIO = "ANADE_AL_MENOS_UN_USUARIO",
  GRUPOS = "GRUPOS",
  YA_HA_SIDO_ANADIDO_ESTE_GRUPO = "YA_HA_SIDO_ANADIDO_ESTE_GRUPO",
  ELIMINAR_USUARIOS = "ELIMINAR_USUARIOS",
  LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES = "LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES",
  EL_USUARIO_NO_EXISTE = "EL_USUARIO_NO_EXISTE",
  EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE = "EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE",
  TIPO = "TIPO",
  TIPO_DE_USUARIO_OBLIGATORIO = "TIPO_DE_USUARIO_OBLIGATORIO",
  CODIGO_DE_COLOR_NO_VALIDO = "CODIGO_DE_COLOR_NO_VALIDO",
  ROL_REPETIDO = "ROL_REPETIDO",
  EXPLOTACIONES = "EXPLOTACIONES",
  LOG = "LOG",
  ADMINISTRACION = "ADMINISTRACION",
  NOMBRE_OBLIGATORIO = "NOMBRE_OBLIGATORIO",
  APELLIDO_OBLIGATORIO = "APELLIDO_OBLIGATORIO",
  EMAIL_OBLIGATORIO = "EMAIL_OBLIGATORIO",
  CONTRASENA_OBLIGATORIA = "CONTRASENA_OBLIGATORIA",
  EXPLOTACION_OBLIGATORIO = "EXPLOTACION_OBLIGATORIO",
  ROL_OBLIGATORIO = "ROL_OBLIGATORIO",
  NIF = "NIF",
  NOMBRE_DE_USUARIO = "NOMBRE_DE_USUARIO",
  CODIGO_SUMA = "CODIGO_SUMA",
  COLOR = "COLOR",
  TIPOS_DE_USUARIO = "TIPOS_DE_USUARIO",
  NOMBRE_DE_USUARIO_OBLIGATORIO = "NOMBRE_DE_USUARIO_OBLIGATORIO",
  TIPO_DE_USUARIO_REPETIDO = "TIPO_DE_USUARIO_REPETIDO",
  EL_USUARIO_ADMINISTRADOR_NO_PUEDE_TENER_MAS_ROLES = "EL_USUARIO_ADMINISTRADOR_NO_PUEDE_TENER_MAS_ROLES",
  GRUPO_DE_USUARIOS = "GRUPO_DE_USUARIOS",
  GRUPOS_DE_USUARIOS = "GRUPOS_DE_USUARIOS",
  NUEVO_GRUPO_DE_USUARIOS = "NUEVO_GRUPO_DE_USUARIOS",
  CREAR_GRUPO_DE_USUARIOS = "CREAR_GRUPO_DE_USUARIOS",
  NO_EXISTEN_GRUPOS_DE_USUARIOS = "NO_EXISTEN_GRUPOS_DE_USUARIOS",
  GRUPO_DE_USUARIOS_CREADO_CORRECTAMENTE = "GRUPO_DE_USUARIOS_CREADO_CORRECTAMENTE",
  GRUPO_DE_USUARIOS_MODIFICADO_CORRECTAMENTE = "GRUPO_DE_USUARIOS_MODIFICADO_CORRECTAMENTE",
  AL_MENOS_DEBE_TENER_UN_ROL_ASIGNADO = "AL_MENOS_DEBE_TENER_UN_ROL_ASIGNADO",
  YA_HA_SIDO_ANADIDO_ESTE_USUARIO = "YA_HA_SIDO_ANADIDO_ESTE_USUARIO",
  YA_HAS_ASIGNADO_ESTE_PERMISO = "YA_HAS_ASIGNADO_ESTE_PERMISO",
  SELECCIONA_UN_USUARIO = "SELECCIONA_UN_USUARIO",
  ELIMINAR_GRUPOS_DE_USUARIOS = "ELIMINAR_GRUPOS_DE_USUARIOS",
  NIF_OBLIGATORIO = "NIF_OBLIGATORIO",
  EL_NIF_NO_ES_VALIDO = "EL_NIF_NO_ES_VALIDO",
  CODIGO_SUMA_OBLIGATORIO = "CODIGO_SUMA_OBLIGATORIO",
  COLOR_OBLIGATORIO = "COLOR_OBLIGATORIO",
  AL_MENOS_DEBE_TENER_UN_TIPO_ASIGNADO = "AL_MENOS_DEBE_TENER_UN_TIPO_ASIGNADO",
  ZONA = "ZONA",
  ZONAS = "ZONAS",
  NUEVA_ZONA = "NUEVA_ZONA",
  CREAR_ZONA = "CREAR_ZONA",
  NO_EXISTEN_ZONAS = "NO_EXISTEN_ZONAS",
  ZONA_CREADA_CORRECTAMENTE = "ZONA_CREADA_CORRECTAMENTE",
  ZONA_MODIFICADA_CORRECTAMENTE = "ZONA_MODIFICADA_CORRECTAMENTE",
  ELIMINAR_ZONAS = "ELIMINAR_ZONAS",
  TIPO_DE_EXPLOTACION = "TIPO_DE_EXPLOTACION",
  TIPOS_DE_EXPLOTACION = "TIPOS_DE_EXPLOTACION",
  NUEVO_TIPO_DE_EXPLOTACION = "NUEVO_TIPO_DE_EXPLOTACION",
  CREAR_TIPO_DE_EXPLOTACION = "CREAR_TIPO_DE_EXPLOTACION",
  NO_EXISTEN_TIPOS_DE_EXPLOTACION = "NO_EXISTEN_TIPOS_DE_EXPLOTACION",
  TIPO_DE_EXPLOTACION_CREADO_CORRECTAMENTE = "TIPO_DE_EXPLOTACION_CREADO_CORRECTAMENTE",
  TIPO_DE_EXPLOTACION_MODIFICADO_CORRECTAMENTE = "TIPO_DE_EXPLOTACION_MODIFICADO_CORRECTAMENTE",
  ELIMINAR_TIPOS_DE_EXPLOTACION = "ELIMINAR_TIPOS_DE_EXPLOTACION",
  TIPO_DE_EXPLOTACION_OBLIGATORIO = "TIPO_DE_EXPLOTACION_OBLIGATORIO",
  RESPONSABLE = "RESPONSABLE",
  FECHA_INICIO = "FECHA_INICIO",
  FECHA_FIN = "FECHA_FIN",
  FECHA_INICIO_RECAUDACION = "FECHA_INICIO_RECAUDACION",
  FECHA_FIN_RECAUDACION = "FECHA_FIN_RECAUDACION",
  TIPO_OBLIGATORIO = "TIPO_OBLIGATORIO",
  NOMBRE_DE_LA_EXPLOTACION = "NOMBRE_DE_LA_EXPLOTACION",
  DESCRIPCION_DE_LA_EXPLOTACION = "DESCRIPCION_DE_LA_EXPLOTACION",
  USUARIOS_DE_LA_EXPLOTACION = "USUARIOS_DE_LA_EXPLOTACION",
  NUEVA_EXPLOTACION = "NUEVA_EXPLOTACION",
  CREAR_EXPLOTACION = "CREAR_EXPLOTACION",
  HABILITADO = "HABILITADO",
  HABILITADA = "HABILITADA",
  DESAHABILITADA = "DESAHABILITADA",
  DESHABILITADO = "DESHABILITADO",
  NO_EXISTEN_EXPLOTACIONES = "NO_EXISTEN_EXPLOTACIONES",
  EXPLOTACION_MODIFICADA_CORRECTAMENTE = "EXPLOTACION_MODIFICADA_CORRECTAMENTE",
  EXPLOTACION_CREADA_CORRECTAMENTE = "EXPLOTACION_CREADA_CORRECTAMENTE",
  ZONA_OBLIGATORIA = "ZONA_OBLIGATORIA",
  ELIMINAR_EXPLOTACIONES = "ELIMINAR_EXPLOTACIONES",
  TIPO_DE_PARQUIMETRO = "TIPO_DE_PARQUIMETRO",
  TIPO_DE_PARQUIMETRO_OBLIGATORIO = "TIPO_DE_PARQUIMETRO_OBLIGATORIO",
  DESTINATARIO = "DESTINATARIO",
  FECHA_ENVIO = "FECHA_ENVIO",
  ALERTA = "ALERTA",
  NOMBRE_DE_LA_ALERTA = "NOMBRE_DE_LA_ALERTA",
  DESCRIPCION_DE_LA_ALERTA = "DESCRIPCION_DE_LA_ALERTA",
  ENLAZAR_CON = "ENLAZAR_CON",
  ENVIAR = "ENVIAR",
  INACTIVA = "INACTIVA",
  ACTIVA = "ACTIVA",
  ELIMINAR_ALERTA = "ELIMINAR_ALERTA",
  ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTA_ALERTA = "ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTA_ALERTA",
  CREAR_ALERTA = "CREAR_ALERTA",
  NUEVA_ALERTA = "NUEVA_ALERTA",
  HORA_ENVIO = "HORA_ENVIO",
  NO_EXISTEN_ALERTAS = "NO_EXISTEN_ALERTAS",
  ELIMINAR_ALERTAS = "ELIMINAR_ALERTAS",
  VER = "VER",
  RESOLVER = "RESOLVER",
  RESUELTA = "RESUELTA",
  MIS_ALERTAS = "MIS_ALERTAS",
  ALERTA_MODIFICADA_CORRECTAMENTE = "ALERTA_MODIFICADA_CORRECTAMENTE",
  ALERTA_CREADA_CORRECTAMENTE = "ALERTA_CREADA_CORRECTAMENTE",
  NO_HAY_ALERTAS = "NO_HAY_ALERTAS",
  FICHERO_CARGADO_CORRECTAMENTE = "FICHERO_CARGADO_CORRECTAMENTE",
  ARRASTRA_LOS_ARCHIVOS_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS = "ARRASTRA_LOS_ARCHIVOS_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS",
  ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO = "ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO",
  LOGS = "LOGS",
  NO_EXISTEN_LOGS = "NO_EXISTEN_LOGS",
  TIPOS_ZONAS_APARCAMIENTOS = "TIPOS_ZONAS_APARCAMIENTOS",
  ELIMINAR_TIPOS_ZONAS_APARCAMIENTOS = "ELIMINAR_TIPOS_ZONAS_APARCAMIENTOS",
  TIPO_ZONA_APARCAMIENTO = "TIPO_ZONA_APARCAMIENTO",
  NUEVO_TIPO_ZONA_APARCAMIENTO = "NUEVO_TIPO_ZONA_APARCAMIENTO",
  TIPO_ZONA_APARCAMIENTO_MODIFICADO_CORRECTAMENTE = "TIPO_ZONA_APARCAMIENTO_MODIFICADO_CORRECTAMENTE",
  TIPO_ZONA_APARCAMIENTO_CREADO_CORRECTAMENTE = "TIPO_ZONA_APARCAMIENTO_CREADO_CORRECTAMENTE",
  NO_EXISTEN_TIPOS_DE_ZONA_DE_APARCAMIENTO = "NO_EXISTEN_TIPOS_DE_ZONA_DE_APARCAMIENTO",
  RUTAS = "RUTAS",
  NO_EXISTEN_RUTAS = "NO_EXISTEN_RUTAS",
  ELIMINAR_RUTAS = "ELIMINAR_RUTAS",
  RUTA = "RUTA",
  NUEVA_RUTA = "NUEVA_RUTA",
  RUTA_MODIFICADA_CORRECTAMENTE = "RUTA_MODIFICADA_CORRECTAMENTE",
  RUTA_CREADA_CORRECTAMENTE = "RUTA_CREADA_CORRECTAMENTE",
  CALLES = "CALLES",
  NO_EXISTEN_CALLES = "NO_EXISTEN_CALLES",
  ELIMINAR_CALLES = "ELIMINAR_CALLES",
  N_PLAZAS = "N_PLAZAS",
  N_PLAZAS_OBLIGATIORIO = "N_PLAZAS_OBLIGATIORIO",
  TIPO_DE_CALLE = "TIPO_DE_CALLE",
  CALLE = "CALLE",
  NUEVA_CALLE = "NUEVA_CALLE",
  TIPO_DE_ZONA_OBLIGATORIO = "TIPO_DE_ZONA_OBLIGATORIO",
  TIPO_DE_CALLE_OBLIGATORIO = "TIPO_DE_CALLE_OBLIGATORIO",
  RUTA_OBLIGATORIA = "RUTA_OBLIGATORIA",
  ESPACIOS_OBLIGATORIOS = "ESPACIOS_OBLIGATORIOS",
  CALLE_MODIFICADA_CORRECTAMENTE = "CALLE_MODIFICADA_CORRECTAMENTE",
  CALLE_CREADA_CORRECTAMENTE = "CALLE_CREADA_CORRECTAMENTE",
  PARQUIMETROS = "PARQUIMETROS",
  NO_EXISTEN_PARQUIMETROS = "NO_EXISTEN_PARQUIMETROS",
  ELIMINAR_PARQUIMETROS = "ELIMINAR_PARQUIMETROS",
  CODIGO = "CODIGO",
  DESCRIPCION = "DESCRIPCION",
  LATITUD = "LATITUD",
  LONGITUD = "LONGITUD",
  PARQUIMETRO = "PARQUIMETRO",
  NUEVO_PARQUIMETRO = "NUEVO_PARQUIMETRO",
  CODIGO_OBLIGATORIO = "CODIGO_OBLIGATORIO",
  DESCRIPCION_OBLIGATORIA = "DESCRIPCION_OBLIGATORIA",
  LATITUD_OBLIGATORIA = "LATITUD_OBLIGATORIA",
  LONGITUD_OBLIGATORIA = "LONGITUD_OBLIGATORIA",
  PARQUIMETRO_MODIFICADO_CORRECTAMENTE = "PARQUIMETRO_MODIFICADO_CORRECTAMENTE",
  PARQUIMETRO_CREADO_CORRECTAMENTE = "PARQUIMETRO_CREADO_CORRECTAMENTE",
  COORDENADAS = "COORDENADAS",
  COORDENADAS_OBLIGATORIAS = "COORDENADAS_OBLIGATORIAS",
  PARQUIMETROS_INCIDENCIAS = "PARQUIMETROS_INCIDENCIAS",
  PARQUIMETRO_INCIDENCIA = "PARQUIMETRO_INCIDENCIA",
  NUEVO_PARQUIMETRO_INCIDENCIA = "NUEVO_PARQUIMETRO_INCIDENCIA",
  ESTADO_INCIDENCIA = "ESTADO_INCIDENCIA",
  OBSERVACIONES = "OBSERVACIONES",
  PARQUIMETRO_OBLIGATORIO = "PARQUIMETRO_OBLIGATORIO",
  ESTADO_OBLIGATORIO = "ESTADO_OBLIGATORIO",
  CONTROLADOR = "CONTROLADOR",
  FECHA_SOLUCIONADA = "FECHA_SOLUCIONADA",
  SOLUCIONADA_POR_CONTROLADOR = "SOLUCIONADA_POR_CONTROLADOR",
  NO_EXISTEN_INCIDENCIAS = "NO_EXISTEN_INCIDENCIAS",
  ESTADO = "ESTADO",
  SOLUCIONADA = "SOLUCIONADA",
  NO_SOLUCIONADA = "NO_SOLUCIONADA",
  PARQUIMETRO_RECAUDACION = "PARQUIMETRO_RECAUDACION",
  PARQUIMETRO_RECAUDACIONES = "PARQUIMETRO_RECAUDACIONES",
  EFECTIVO = "EFECTIVO",
  NUMERO_DE_BRIDA = "NUMERO_DE_BRIDA",
  NO_EXISTEN_RECAUDACIONES = "NO_EXISTEN_RECAUDACIONES",
  NUEVO_PARQUIMETRO_RECAUDACION = "NUEVO_PARQUIMETRO_RECAUDACION",
  EXPORTAR_EXCEL = "EXPORTAR_EXCEL",
  SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES = "SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_LAS_RECAUDACIONES",
  PLAZAS_PERDIDAS = "PLAZAS_PERDIDAS",
  NO_EXISTEN_PLAZAS_PERDIDAS = "NO_EXISTEN_PLAZAS_PERDIDAS",
  MOTIVO = "MOTIVO",
  PLAZA_PERDIDA = "PLAZA_PERDIDA",
  ELEMENTO = "ELEMENTO",
  ELEMENTOS = "ELEMENTOS",
  ELIMINADO_CORRECTAMENTE = "ELIMINADO_CORRECTAMENTE",
  NO_SE_HA_ENCONTRADO = "NO_SE_HA_ENCONTRADO",
  NO_SE_HAN_ENCONTRADO = "NO_SE_HAN_ENCONTRADO",
  NO_SE_PERMITE_BORRAR = "NO_SE_PERMITE_BORRAR",
  NO_SE_PERMITEN_BORRAR = "NO_SE_PERMITEN_BORRAR",
  NO_TIENES_PERMISO_PARA_ELIMINAR = "NO_TIENES_PERMISO_PARA_ELIMINAR",
  NO_TIENES_PERMISOS_PARA_ELIMINAR = "NO_TIENES_PERMISOS_PARA_ELIMINAR",
  ADVERACION_FIRMADA = "ADVERACION_FIRMADA",
  ARTICULO_DE_LA_DENUNCIA = "ARTICULO_DE_LA_DENUNCIA",
  BORRAR_FILTROS = "BORRAR_FILTROS",
  DENUNCIA_FIRMADA = "DENUNCIA_FIRMADA",
  ESTADO_ADVERACION = "ESTADO_ADVERACION",
  ESTADO_DE_TRAMITE_DE_DENUNCIA = "ESTADO_DE_TRAMITE_DE_DENUNCIA",
  MATRICULA = "MATRICULA",
  NUMERO_DE_DENUNCIA = "NUMERO_DE_DENUNCIA",
  RATIFICADOR = "RATIFICADOR",
  ADVERADA = "ADVERADA",
  ENVIADA = "ENVIADA",
  NO_ENVIADA = "NO_ENVIADA",
  PENDIENTE = "PENDIENTE",
  DENUNCIAS = "DENUNCIAS",
  ESTADO_DE_ADVERACION = "ESTADO_DE_ADVERACION",
  FECHA_Y_HORA_DE_DENUNCIA = "FECHA_Y_HORA_DE_DENUNCIA",
  MARCA = "MARCA",
  MODELO = "MODELO",
  TIPO_DE_VEHICULO = "TIPO_DE_VEHICULO",
  GESTION = "GESTION",
  INCIDENCIAS_PARQUIMETROS = "INCIDENCIAS_PARQUIMETROS",
  INCIDENCIA_PARQUIMETRO = "INCIDENCIA_PARQUIMETRO",
  NUEVA_INCIDENCIA_PARQUIMETRO = "NUEVA_INCIDENCIA_PARQUIMETRO",
  LA_POSICION_ES_OBLIGATORIA = "LA_POSICION_ES_OBLIGATORIA",
  POSICION = "POSICION",
  NO_EXISTEN_DENUNCIAS = "NO_EXISTEN_DENUNCIAS",
  RECAUDACIONES_PARQUIMETROS = "RECAUDACIONES_PARQUIMETROS",
  RECAUDACION_PARQUIMETRO = "RECAUDACION_PARQUIMETRO",
  ARTICULOS = "ARTICULOS",
  NO_EXISTEN_ARTICULOS = "NO_EXISTEN_ARTICULOS",
  ELIMINAR_ARTICULOS = "ELIMINAR_ARTICULOS",
  TIPO_DE_LEY = "TIPO_DE_LEY",
  CODIGO_MUNICIPAL = "CODIGO_MUNICIPAL",
  IMPORTE = "IMPORTE",
  TABLAS_MAESTRAS = "TABLAS_MAESTRAS",
  IMPORTE_ANULACION = "IMPORTE_ANULACION",
  DENUNCIA_ANULABLE = "DENUNCIA_ANULABLE",
  ANULABLE = "ANULABLE",
  NO_ANULABLE = "NO_ANULABLE",
  ARTICULO = "ARTICULO",
  APARTADO = "APARTADO",
  OPCION = "OPCION",
  ARTICULO_OBLIGATORIO = "ARTICULO_OBLIGATORIO",
  APARTADO_OBLIGATORIO = "APARTADO_OBLIGATORIO",
  OPCION_OBLIGATORIA = "OPCION_OBLIGATORIA",
  NUEVO_ARTICULO = "NUEVO_ARTICULO",
  TEXTO_A_MOSTRAR_EN_PANTALLA = "TEXTO_A_MOSTRAR_EN_PANTALLA",
  TEXTO_DE_IMPRESION = "TEXTO_DE_IMPRESION",
  FORMATO_DE_VISUALIZACION_EN_TICKET = "FORMATO_DE_VISUALIZACION_EN_TICKET",
  TEXTO_DE_ANULACION = "TEXTO_DE_ANULACION",
  NUMERO_DE_FOTOGRAFIAS_PARA_CONTROLADOR = "NUMERO_DE_FOTOGRAFIAS_PARA_CONTROLADOR",
  TIEMPO_DE_TICKET_EXCEDEDIDO = "TIEMPO_DE_TICKET_EXCEDEDIDO",
  CODIGO_CANCELACION = "CODIGO_CANCELACION",
  CODIGO_CANCELACION_OBLIGATORIO = "CODIGO_CANCELACION_OBLIGATORIO",
  FECHA_HORA_REFERENCIA_ANULACION_APP = "FECHA_HORA_REFERENCIA_ANULACION_APP",
  MINUTOS_DE_EXCESO_PERMITIDOS_PARA_ANULACION_EN_APP = "MINUTOS_DE_EXCESO_PERMITIDOS_PARA_ANULACION_EN_APP",
  TIPO_DE_CANCELACION = "TIPO_DE_CANCELACION",
  TIPO_DE_LEY_OBLIGATORIO = "TIPO_DE_LEY_OBLIGATORIO",
  CODIGO_MUNICIPAL_OBLIGATORIO = "CODIGO_MUNICIPAL_OBLIGATORIO",
  TEXTO_A_MOSTRAR_EN_PANTALLA_OBLIGATORIO = "TEXTO_A_MOSTRAR_EN_PANTALLA_OBLIGATORIO",
  TEXTO_SUPERADO_250_CARACTERES = "TEXTO_SUPERADO_250_CARACTERES",
  TEXTO_DE_IMPRESION_OBLIGATORIO = "TEXTO_DE_IMPRESION_OBLIGATORIO",
  FORMATO_OBLIGATORIO = "FORMATO_OBLIGATORIO",
  IMPORTE_OBLIGATORIO = "IMPORTE_OBLIGATORIO",
  TEXTO_A_LA_ANULACION_OBLIGATORIO = "TEXTO_A_LA_ANULACION_OBLIGATORIO",
  DEBE_INDICAR_EL_NUMERO_DE_FOTOGRAFIAS = "DEBE_INDICAR_EL_NUMERO_DE_FOTOGRAFIAS",
  MINUTOS_EN_SCADA_OBLIGATORIO = "MINUTOS_EN_SCADA_OBLIGATORIO",
  MINUTOS_DE_EXCESO_OBLIGATORIO = "MINUTOS_DE_EXCESO_OBLIGATORIO",
  TIPO_DE_CANCELACION_OBLIGATORIO = "TIPO_DE_CANCELACION_OBLIGATORIO",
  IMPORTE_ANULACION_OBLIGATORIO = "IMPORTE_ANULACION_OBLIGATORIO",
  ARTICULO_MODIFICADO_CORRECTAMENTE = "ARTICULO_MODIFICADO_CORRECTAMENTE",
  ARTICULO_CREADO_CORRECTAMENTE = "ARTICULO_CREADO_CORRECTAMENTE",
  SUMA_CODE_SOLO_ADMITE_VALORES_DEL_1_AL_99 = "SUMA_CODE_SOLO_ADMITE_VALORES_DEL_1_AL_99",
  SUMA_CODE_SOLO_ADMITE_VALORES_DEL_1_AL_X = "SUMA_CODE_SOLO_ADMITE_VALORES_DEL_1_AL_X",
  CONFIGURACION_DE_LISTAS_NEGRAS = "CONFIGURACION_DE_LISTAS_NEGRAS",
  NO_EXISTEN_CONFIGURACIONES_DE_LISTAS_NEGRAS = "NO_EXISTEN_CONFIGURACIONES_DE_LISTAS_NEGRAS",
  ELIMINAR_CONFIGURACIONES_DE_LISTAS_NEGRAS = "ELIMINAR_CONFIGURACIONES_DE_LISTAS_NEGRAS",
  MULTAS_ACUMULADAS = "MULTAS_ACUMULADAS",
  DIAS = "DIAS",
  CONFIGURACION_LISTA_NEGRA = "CONFIGURACION_LISTA_NEGRA",
  NUEVA_CONFIGURACION_LISTA_NEGRA = "NUEVA_CONFIGURACION_LISTA_NEGRA",
  NUMERO_DE_MULTAS_ACUMULADAS_SIN_ANULAR_NI_INVALIDAR = "NUMERO_DE_MULTAS_ACUMULADAS_SIN_ANULAR_NI_INVALIDAR",
  DIAS_ATRAS_PARA_REALIZAR_LA_MEDICION = "DIAS_ATRAS_PARA_REALIZAR_LA_MEDICION",
  NUMERO_DE_MULTAS_OBLIGATORIO = "NUMERO_DE_MULTAS_OBLIGATORIO",
  NUMERO_DE_DIAS_OBLIGATORIO = "NUMERO_DE_DIAS_OBLIGATORIO",
  CONFIGURACION_DE_LISTA_NEGRA_MODIFICADA_CORRECTAMENTE = "CONFIGURACION_DE_LISTA_NEGRA_MODIFICADA_CORRECTAMENTE",
  CONFIGURACION_DE_LISTA_NEGRA_CREADA_CORRECTAMENTE = "CONFIGURACION_DE_LISTA_NEGRA_CREADA_CORRECTAMENTE",
  IDENTIFICADOR = "IDENTIFICADOR",
  CONTROLADORES = "CONTROLADORES",
  NO_EXISTEN_INFORMES_DE_CONTROLADORES = "NO_EXISTEN_INFORMES_DE_CONTROLADORES",
  INICIO_DE_JORNADA = "INICIO_DE_JORNADA",
  FIN_DE_JORNADA = "FIN_DE_JORNADA",
  HORAS_EFECTIVAS = "HORAS_EFECTIVAS",
  DENUNCIAS_PUESTAS = "DENUNCIAS_PUESTAS",
  DENUNCIAS_INVALIDADAS = "DENUNCIAS_INVALIDADAS",
  SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_EL_INFORME_DE_CONTROLADORES = "SE_HA_PRODUCIDO_UN_ERROR_EXPORTANDO_EL_INFORME_DE_CONTROLADORES",
  ADVERACION = "ADVERACION",
  DETALLES_DENUNCIA = "DETALLES_DENUNCIA",
  CIUDAD = "CIUDAD",
  IMPORTE_A_PAGAR = "IMPORTE_A_PAGAR",
  ESTADO_DE_LA_DENUNCIA = "ESTADO_DE_LA_DENUNCIA",
  MAPA_DE_SITUACION = "MAPA_DE_SITUACION",
  TRAMITE_DE_LA_DENUNCIA = "TRAMITE_DE_LA_DENUNCIA",
  DIRECCION = "DIRECCION",
  TIPO_DE_ZONA = "TIPO_DE_ZONA",
  NOTIFICACION_DE_LA_GRUA = "NOTIFICACION_DE_LA_GRUA",
  LEY = "LEY",
  LEY_SIN_TICKET = "LEY_SIN_TICKET",
  LEY_EXCESO_TICKET = "LEY_EXCESO_TICKET",
  VEHICULO = "VEHICULO",
  FIRMAS = "FIRMAS",
  FIRMA_DEL_CONTROLADOR = "FIRMA_DEL_CONTROLADOR",
  FIRMA_DEL_ADVERADOR = "FIRMA_DEL_ADVERADOR",
  FOTOGRAFIAS = "FOTOGRAFIAS",
  FOTOGRAFIAS_DEL_CONTROLADOR = "FOTOGRAFIAS_DEL_CONTROLADOR",
  EXPORTAR_A_PDF = "EXPORTAR_A_PDF",
  AVISADA = "AVISADA",
  NO_AVISADA = "NO_AVISADA",
  ESCOJA_UN_MOTIVO = "ESCOJA_UN_MOTIVO",
  ANULAR = "ANULAR",
  ANULACION = "ANULACION",
  T_ANULACION_DE_LAS_DENUNCIAS_SELECCIONADAS = "T_ANULACION_DE_LAS_DENUNCIAS_SELECCIONADAS",
  INVALIDAR = "INVALIDAR",
  INVALIDACION = "INVALIDACION",
  INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS = "INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS",
  OBSERVACIONES_DE_INVALIDACION = "OBSERVACIONES_DE_INVALIDACION",
  RECTIFICAR_ANULACION_INVALIDACION = "RECTIFICAR_ANULACION_INVALIDACION",
  RECTIFICAR = "RECTIFICAR",
  RECTIFICACION = "RECTIFICACION",
  RECTIFICACION_DE_LA_ANULACION_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS = "RECTIFICACION_DE_LA_ANULACION_INVALIDACION_DE_LAS_DENUNCIAS_SELECCIONADAS",
  ENVIAR_A_TRAMITE = "ENVIAR_A_TRAMITE",
  ENVIO_A_TRAMITE = "ENVIO_A_TRAMITE",
  ENVIO_A_TRAMITE_DE_LAS_DENUNCIAS_SELECCIONADAS = "ENVIO_A_TRAMITE_DE_LAS_DENUNCIAS_SELECCIONADAS",
  ADVERAR_DENUNCIA = "ADVERAR_DENUNCIA",
  ANULAR_ADVERACION = "ANULAR_ADVERACION",
  FOTOGRAFIAS_DEL_ADVERADOR = "FOTOGRAFIAS_DEL_ADVERADOR",
  DENUNCIA_ADVERADA = "DENUNCIA_ADVERADA",
  ADVERACION_ANULADA = "ADVERACION_ANULADA",
  ATRAS = "ATRAS",
  VOLVER = "VOLVER",
  ADVERADOR = "ADVERADOR",
  FIRMA = "FIRMA",
  FECHA_DENUNCIA = "FECHA_DENUNCIA",
  HORA_DENUNCIA = "HORA_DENUNCIA",
  DATOS_DENUNCIA = "DATOS_DENUNCIA",
  INFORME_CONTROLADORES = "INFORME_CONTROLADORES",
  HORAS = "HORAS",
  INVALIDADAS = "INVALIDADAS",
  SE_HA_PRODUCIDO_UN_ERROR_EN_LA_EXPORTACION = "SE_HA_PRODUCIDO_UN_ERROR_EN_LA_EXPORTACION",
  DENUNCIA_EXPORTADA_COMO_PDF_A = "DENUNCIA_EXPORTADA_COMO_PDF_A",
  METODOS_DE_CANCELACION_DE_DENUNCIA = "METODOS_DE_CANCELACION_DE_DENUNCIA",
  NO_EXISTEN_METODOS_DE_CANCELACION_DE_DENUNCIA = "NO_EXISTEN_METODOS_DE_CANCELACION_DE_DENUNCIA",
  ELIMINAR_METODOS = "ELIMINAR_METODOS",
  METODO_DE_CANCELACION_DE_DENUNCIA = "METODO_DE_CANCELACION_DE_DENUNCIA",
  NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA = "NUEVO_METODO_DE_CANCELACION_DE_DENUNCIA",
  METODO_DE_CANCELACION_DE_DENUNCIA_MODIFICADO_CORRECTAMENTE = "METODO_DE_CANCELACION_DE_DENUNCIA_MODIFICADO_CORRECTAMENTE",
  METODO_DE_CANCELACION_DE_DENUNCIA_CREADO_CORRECTAMENTE = "METODO_DE_CANCELACION_DE_DENUNCIA_CREADO_CORRECTAMENTE",
  COLORES_DE_VEHICULOS = "COLORES_DE_VEHICULOS",
  NO_EXISTEN_COLORES_DE_VEHICULOS = "NO_EXISTEN_COLORES_DE_VEHICULOS",
  ELIMINAR_COLORES = "ELIMINAR_COLORES",
  COLOR_DE_VEHICULO = "COLOR_DE_VEHICULO",
  NUEVO_COLOR_DE_VEHICULO = "NUEVO_COLOR_DE_VEHICULO",
  COLOR_DE_VEHICULO_MODIFICADO_CORRECTAMENTE = "COLOR_DE_VEHICULO_MODIFICADO_CORRECTAMENTE",
  COLOR_DE_VEHICULO_CREADO_CORRECTAMENTE = "COLOR_DE_VEHICULO_CREADO_CORRECTAMENTE",
  MARCAS_DE_VEHICULOS = "MARCAS_DE_VEHICULOS",
  NO_EXISTEN_MARCAS_DE_VEHICULOS = "NO_EXISTEN_MARCAS_DE_VEHICULOS",
  ELIMINAR_MARCAS = "ELIMINAR_MARCAS",
  MARCA_DE_VEHICULO = "MARCA_DE_VEHICULO",
  NUEVA_MARCA_DE_VEHICULO = "NUEVA_MARCA_DE_VEHICULO",
  MARCA_DE_VEHICULO_MODIFICADA_CORRECTAMENTE = "MARCA_DE_VEHICULO_MODIFICADA_CORRECTAMENTE",
  MARCA_DE_VEHICULO_CREADA_CORRECTAMENTE = "MARCA_DE_VEHICULO_CREADA_CORRECTAMENTE",
  MODELOS_DE_VEHICULOS = "MODELOS_DE_VEHICULOS",
  NO_EXISTEN_MODELOS_DE_VEHICULOS = "NO_EXISTEN_MODELOS_DE_VEHICULOS",
  ELIMINAR_MODELOS = "ELIMINAR_MODELOS",
  MODELO_OBLIGATORIO = "MODELO_OBLIGATORIO",
  MODELO_DE_VEHICULO = "MODELO_DE_VEHICULO",
  NUEVO_MODELO_DE_VEHICULO = "NUEVO_MODELO_DE_VEHICULO",
  MODELO_DE_VEHICULO_CREADO_CORRECTAMENTE = "MODELO_DE_VEHICULO_CREADO_CORRECTAMENTE",
  MODELO_DE_VEHICULO_MODIFICADO_CORRECTAMENTE = "MODELO_DE_VEHICULO_MODIFICADO_CORRECTAMENTE",
  VEHICULOS = "VEHICULOS",
  NO_EXISTEN_VEHICULOS = "NO_EXISTEN_VEHICULOS",
  ELIMINAR_VEHICULOS = "ELIMINAR_VEHICULOS",
  MASCARA = "MASCARA",
  ESPECIAL = "ESPECIAL",
  NUEVO_VEHICULO = "NUEVO_VEHICULO",
  MATRICULA_OBLIGATORIA = "MATRICULA_OBLIGATORIA",
  MASCARA_OBLIGATORIA = "MASCARA_OBLIGATORIA",
  VEHICULO_MODIFICADO_CORRECTAMENTE = "VEHICULO_MODIFICADO_CORRECTAMENTE",
  VEHICULO_CREADO_CORRECTAMENTE = "VEHICULO_CREADO_CORRECTAMENTE",
  RAZONES_DE_INVALIDACION = "RAZONES_DE_INVALIDACION",
  NO_EXISTEN_RAZONES = "NO_EXISTEN_RAZONES",
  ELIMINAR_RAZONES = "ELIMINAR_RAZONES",
  RAZONES = "RAZONES",
  RAZON = "RAZON",
  NUEVA_RAZON = "NUEVA_RAZON",
  RAZON_DE_INVALIDACION = "RAZON_DE_INVALIDACION",
  NUEVA_RAZON_DE_INVALIDACION = "NUEVA_RAZON_DE_INVALIDACION",
  RAZON_DE_INVALIDACION_MODIFICADA_CORRECTAMENTE = "RAZON_DE_INVALIDACION_MODIFICADA_CORRECTAMENTE",
  RAZON_DE_INVALIDACION_CREADA_CORRECTAMENTE = "RAZON_DE_INVALIDACION_CREADA_CORRECTAMENTE",
  UPS = "UPS",
  MANAGER_CONTROL_PLUS = "MANAGER_CONTROL_PLUS",
  INICIAR_SESION = "INICIAR_SESION",
  CARGANDO_PUNTOS = "CARGANDO_PUNTOS",
  HOLA_NOS_GUSTARIA_QUE_CAMBIARAS_TU_CONTRASENA_POR_SEGURIDAD_PLACEHOLDER = "HOLA_NOS_GUSTARIA_QUE_CAMBIARAS_TU_CONTRASENA_POR_SEGURIDAD_PLACEHOLDER",
  RECUERDA_NO_INTRODUCIR_LA_MISMA_CONTRASENA_QUE_LAS_ULTIMAS_3_VECES = "RECUERDA_NO_INTRODUCIR_LA_MISMA_CONTRASENA_QUE_LAS_ULTIMAS_3_VECES",
  TODAS = "TODAS",
  PUESTAS = "PUESTAS",
  ADVERACIONES_PENDIENTES = "ADVERACIONES_PENDIENTES",
  INVALIDADAS_Y_ANULADAS = "INVALIDADAS_Y_ANULADAS",
  BUSCAR_DENUNCIA = "BUSCAR_DENUNCIA",
  HORA_Y_FECHA = "HORA_Y_FECHA",
  NUMERO_DE_MATRICULA = "NUMERO_DE_MATRICULA",
  MARCA_MODELO_Y_COLOR = "MARCA_MODELO_Y_COLOR",
  CALLE_Y_NUMERO = "CALLE_Y_NUMERO",
  LEY_INCUMPLIDA = "LEY_INCUMPLIDA",
  CODIGO_C60 = "CODIGO_C60",
  NINGUNA_ADVERACION = "NINGUNA_ADVERACION",
  INVALIDAR_DENUNCIA = "INVALIDAR_DENUNCIA",
  AVISAR_A_GRUA = "AVISAR_A_GRUA",
  ESTADO_DE_LA_ADVERACION = "ESTADO_DE_LA_ADVERACION",
  SI_AVISO_MARCADO = "SI_AVISO_MARCADO",
  IR_A_MAPA = "IR_A_MAPA",
  ULTIMA_BUSQUEDA = "ULTIMA_BUSQUEDA",
  ESCANEAR_CODIGO = "ESCANEAR_CODIGO",
  CHAT = "CHAT",
  HOY = "HOY",
  AYER = "AYER",
  ESCRIBIR_MENSAJE = "ESCRIBIR_MENSAJE",
  EL_USUARIO_HA_SIDO_BLOQUEADO = "EL_USUARIO_HA_SIDO_BLOQUEADO",
  NO_PODRAS_VOLVERLO_A_INTENTAR_HASTA_PASADAS_2_HORAS = "NO_PODRAS_VOLVERLO_A_INTENTAR_HASTA_PASADAS_2_HORAS",
  DE_ACUERDO = "DE_ACUERDO",
  HOLA_PLACEHOLDER = "HOLA_PLACEHOLDER",
  INDICA_LA_RUTA_QUE_VAS_A_REALIZAR_EN_TU_JORNADA_LABORAL_DE_HOY = "INDICA_LA_RUTA_QUE_VAS_A_REALIZAR_EN_TU_JORNADA_LABORAL_DE_HOY",
  INICIAR_RUTA = "INICIAR_RUTA",
  CONTRASENA_CAMBIADA_CORRECTAMENTE = "CONTRASENA_CAMBIADA_CORRECTAMENTE",
  PUBLICAR_MENSAJE = "PUBLICAR_MENSAJE",
  MENSAJES_A_TODOS = "MENSAJES_A_TODOS",
  NO_EXISTEN_MENSAJES = "NO_EXISTEN_MENSAJES",
  MENSAJE = "MENSAJE",
  CADUCIDAD = "CADUCIDAD",
  NUEVO_MENSAJE = "NUEVO_MENSAJE",
  FECHA_EXPIRACION = "FECHA_EXPIRACION",
  MENSAJE_OBLIGATORIO = "MENSAJE_OBLIGATORIO",
  FECHA_DE_EXPIRACION_OBLIGATORIA = "FECHA_DE_EXPIRACION_OBLIGATORIA",
  MENSAJE_CREADO_CORRECTAMENTE = "MENSAJE_CREADO_CORRECTAMENTE",
  SOLO_ADMITE_UN_FICHERO = "SOLO_ADMITE_UN_FICHERO",
  SOLO_SE_ADMITEN_FICHEROS_DE_TIPO_IMAGEN = "SOLO_SE_ADMITEN_FICHEROS_DE_TIPO_IMAGEN",
  TIPO_DE_FICHERO_NO_VALIDO = "TIPO_DE_FICHERO_NO_VALIDO",
  ESTADO_PLACEHOLDER = "ESTADO_PLACEHOLDER",
  FINALIZAR_JORNADA = "FINALIZAR_JORNADA",
  HISTORICO = "HISTORICO",
  VER_MAS = "VER_MAS",
  ESTAS_SEGURO_DE_FINALIZAR_LA_JORNADA = "ESTAS_SEGURO_DE_FINALIZAR_LA_JORNADA",
  AL_FINALIZAR_LA_JORNADA_TAMBIEN_CERRARAS_LA_SESION_DE_TU_PERFIL = "AL_FINALIZAR_LA_JORNADA_TAMBIEN_CERRARAS_LA_SESION_DE_TU_PERFIL",
  NO_GRACIAS = "NO_GRACIAS",
  INICIO_JORNADA = "INICIO_JORNADA",
  FIN_JORNADA = "FIN_JORNADA",
  NO_TIENES_NINGUNA_JORNADA_REGISTRADA = "NO_TIENES_NINGUNA_JORNADA_REGISTRADA",
  EDITAR_PERFIL = "EDITAR_PERFIL",
  INFORMACION_PERSONAL = "INFORMACION_PERSONAL",
  SELECCIONAR_IMAGEN = "SELECCIONAR_IMAGEN",
  HACER_UNA_FOTO = "HACER_UNA_FOTO",
  SELECCIONAR_DE_GALERIA = "SELECCIONAR_DE_GALERIA",
  INFORMACION_ACTUALIZADA = "INFORMACION_ACTUALIZADA",
  INFORMACION = "INFORMACION",
  AJUSTES_IMPRESORA = "AJUSTES_IMPRESORA",
  DAR_PERMISOS = "DAR_PERMISOS",
  PERMISO_DENEGADO = "PERMISO_DENEGADO",
  NO_SE_DISPONE_DE_LOS_PERMISOS_NECESARIOS_PARA_CONTINUAR = "NO_SE_DISPONE_DE_LOS_PERMISOS_NECESARIOS_PARA_CONTINUAR",
  DENUNCIAS_ANULADAS = "DENUNCIAS_ANULADAS",
  DENUNCIAS_RECTIFICADAS = "DENUNCIAS_RECTIFICADAS",
  DESHACER_ENVIO_A_TRAMITE = "DESHACER_ENVIO_A_TRAMITE",
  DESHACER = "DESHACER",
  DENUNCIAS_PUESTAS_COMO_PENDIENTES_DE_ENVIAR_A_TRAMITE = "DENUNCIAS_PUESTAS_COMO_PENDIENTES_DE_ENVIAR_A_TRAMITE",
  DENUNCIAS_PUESTAS_COMO_NO_ENVIADAS_A_TRAMITE = "DENUNCIAS_PUESTAS_COMO_NO_ENVIADAS_A_TRAMITE",
  PONER_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS = "PONER_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS",
  PONER_COMO_NO_ENVIADAS_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS = "PONER_COMO_NO_ENVIADAS_A_TRAMITE_LAS_DENUNCIAS_SELECCIONADAS",
  CONFIRMACION_DE_ENVIO_A_TRAMITE = "CONFIRMACION_DE_ENVIO_A_TRAMITE",
  ENVIAR_A_TRAMITE_TODAS_AQUELLAS_DENUNCIAS_PENDIENTES_DE_ENVIAR = "ENVIAR_A_TRAMITE_TODAS_AQUELLAS_DENUNCIAS_PENDIENTES_DE_ENVIAR",
  ESCOJA_UNA_FECHA_ANTES_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_PENDIENTES_DE_ENVIAR = "ESCOJA_UNA_FECHA_ANTES_DE_ENVIAR_A_TRAMITE_LAS_DENUNCIAS_PENDIENTES_DE_ENVIAR",
  EXPORTAR_LISTA_NEGRA = "EXPORTAR_LISTA_NEGRA",
  HA_OCURRIDO_UN_ERROR_EN_LA_EXPORTACIÓN_DE_LISTA_NEGRA = "HA_OCURRIDO_UN_ERROR_EN_LA_EXPORTACIÓN_DE_LISTA_NEGRA",
  FICHERO_DE_ENVIO_A_TRAMITE = "FICHERO_DE_ENVIO_A_TRAMITE",
  DENUNCIA_EXPORTADA = "DENUNCIA_EXPORTADA",
  DESCARTAR = "DESCARTAR",
  MOTIVO_INFRACCION = "MOTIVO_INFRACCION",
  DATOS_VEHICULO = "DATOS_VEHICULO",
  INFORMACION_DE_LA_VIA = "INFORMACION_DE_LA_VIA",
  SIGUIENTE = "SIGUIENTE",
  DATOS_ESTACIONAMIENTO = "DATOS_ESTACIONAMIENTO",
  BUSCAR_MARCA = "BUSCAR_MARCA",
  BUSCAR_MODELO = "BUSCAR_MODELO",
  MATRICULA_MANUAL = "MATRICULA_MANUAL",
  MATRICULA_POR_VOZ = "MATRICULA_POR_VOZ",
  COMPRUEBA_LA_MATRICULA_MANUALMENTE = "COMPRUEBA_LA_MATRICULA_MANUALMENTE",
  INTRODUCE_MATRICULA = "INTRODUCE_MATRICULA",
  NUM_ACRON = "NUM_ACRON",
  SITUACION = "SITUACION",
  LOCALIZARME = "LOCALIZARME",
  ANYADIR_LOCALIZACION = "ANYADIR_LOCALIZACION",
  TICKET_MOVILTIK_ORA = "TICKET_MOVILTIK_ORA",
  VEHICULO_EN_LISTA_NEGRA = "VEHICULO_EN_LISTA_NEGRA",
  VEHICULO_ESPECIAL = "VEHICULO_ESPECIAL",
  VEHICULO_SIN_TICKET = "VEHICULO_SIN_TICKET",
  LA_MATRICULA_INTRODUCIDA_NO_TIENE_TICKET = "LA_MATRICULA_INTRODUCIDA_NO_TIENE_TICKET",
  VOLVER_A_INTRODUCIR_MATRICULA = "VOLVER_A_INTRODUCIR_MATRICULA",
  ANYADIR_DENUNCIA = "ANYADIR_DENUNCIA",
  LOS_VEHICULOS_ESPECIALES_NO_NECESITAN_TICKET = "LOS_VEHICULOS_ESPECIALES_NO_NECESITAN_TICKET",
  LISTAS_A_LA_QUE_ESTA_INSCRITO_EL_VEHICULO = "LISTAS_A_LA_QUE_ESTA_INSCRITO_EL_VEHICULO",
  INICIO_ESTACIONAMIENTO = "INICIO_ESTACIONAMIENTO",
  FIN_ESTACIONAMIENTO = "FIN_ESTACIONAMIENTO",
  TICKET_RESIDENTE = "TICKET_RESIDENTE",
  COMPROBAR = "COMPROBAR",
  LUGAR = "LUGAR",
  TELEFONO = "TELEFONO",
  POBLACION = "POBLACION",
  PROVINCIA = "PROVINCIA",
  CODIFICACION_DE_DENUNCIA_Y_TRAMITACION = "CODIFICACION_DE_DENUNCIA_Y_TRAMITACION",
  TIPO_DE_CODIFICACION_DE_DENUNCIA = "TIPO_DE_CODIFICACION_DE_DENUNCIA",
  TIPO_DE_CODIFICACION_DE_CANCELACION = "TIPO_DE_CODIFICACION_DE_CANCELACION",
  TIPO_DE_GESTION_DE_TRAMITACION = "TIPO_DE_GESTION_DE_TRAMITACION",
  TIPO_DE_CODIFICACION_DE_DENUNCIA_OBLIGATORIO = "TIPO_DE_CODIFICACION_DE_DENUNCIA_OBLIGATORIO",
  TIPO_DE_GESTION_DE_TRAMITACION_OBLIGATORIA = "TIPO_DE_GESTION_DE_TRAMITACION_OBLIGATORIA",
  ADVERACION_RATIFICACION_REMOTA = "ADVERACION_RATIFICACION_REMOTA",
  IDENTIFICACION_DE_DENUNCIADO = "IDENTIFICACION_DE_DENUNCIADO",
  FIRMA_DIGITAL = "FIRMA_DIGITAL",
  GENERACION_C60 = "GENERACION_C60",
  DIRECCION_OBLIGATORIA = "DIRECCION_OBLIGATORIA",
  POBLACION_OBLIGATORIA = "POBLACION_OBLIGATORIA",
  PROVINCIA_OBLIGATORIA = "PROVINCIA_OBLIGATORIA",
  TELEFONO_OBLIGATORIO = "TELEFONO_OBLIGATORIO",
  IMPRESION_DENUNCIA = "IMPRESION_DENUNCIA",
  ICONO_PARA_IMPRESION = "ICONO_PARA_IMPRESION",
  NOMBRE_DE_LA_EXPLOTACION_PARA_TICKET = "NOMBRE_DE_LA_EXPLOTACION_PARA_TICKET",
  IMPRIMIR_IMPORTE = "IMPRIMIR_IMPORTE",
  IMPRIMIR_IMPORTE_REDUCIDO = "IMPRIMIR_IMPORTE_REDUCIDO",
  ICONO_OBLIGATORIO = "ICONO_OBLIGATORIO",
  ERROR_EN_EL_SERVIDOR = "ERROR_EN_EL_SERVIDOR",
  IMPRIMIR_TIEMPO_EXCEDIDO = "IMPRIMIR_TIEMPO_EXCEDIDO",
  TITULO_1 = "TITULO_1",
  TITULO_2 = "TITULO_2",
  NUMERO = "NUMERO",
  NUMERO_CANCELACION = "NUMERO_CANCELACION",
  NUMERO_ANULACION = "NUMERO_ANULACION",
  HORA = "HORA",
  HORA_LIMITE = "HORA_LIMITE",
  HECHO_DENUNCIADO = "HECHO_DENUNCIADO",
  LUGAR_DE_LA_DENUNCIA = "LUGAR_DE_LA_DENUNCIA",
  PRECEPTO_INFRINGIDO = "PRECEPTO_INFRINGIDO",
  IMPORTE_REDUCIDO = "IMPORTE_REDUCIDO",
  FIRMA_1 = "FIRMA_1",
  FIRMA_2 = "FIRMA_2",
  USO_INTERNO = "USO_INTERNO",
  IMPRIMIR_CODIGO_DE_BARRAS_NUM_DENUNCIA = "IMPRIMIR_CODIGO_DE_BARRAS_NUM_DENUNCIA",
  IMPRIMIR_CODIGO_DE_BARRAS_C60 = "IMPRIMIR_CODIGO_DE_BARRAS_C60",
  TITULO_OBLIGATORIO = "TITULO_OBLIGATORIO",
  NUMERO_OBLIGATORIO = "NUMERO_OBLIGATORIO",
  NUMERO_CANCELACION_OBLIGATORIO = "NUMERO_CANCELACION_OBLIGATORIO",
  VEHICULO_OBLIGATORIO = "VEHICULO_OBLIGATORIO",
  MARCA_OBLIGATORIA = "MARCA_OBLIGATORIA",
  HORA_LIMITE_OBLIGATORIA = "HORA_LIMITE_OBLIGATORIA",
  HECHO_DENUNCIADO_OBLIGATORIO = "HECHO_DENUNCIADO_OBLIGATORIO",
  LUGAR_DE_LA_DENUNCIA_OBLIGATORIO = "LUGAR_DE_LA_DENUNCIA_OBLIGATORIO",
  PRECEPTO_INFRINGIDO_OBLIGATORIO = "PRECEPTO_INFRINGIDO_OBLIGATORIO",
  IMPORTE_REDUCIDO_OBLIGATORIO = "IMPORTE_REDUCIDO_OBLIGATORIO",
  FIRMA_1_OBLIGATORIA = "FIRMA_1_OBLIGATORIA",
  FIRMA_2_OBLIGATORIA = "FIRMA_2_OBLIGATORIA",
  CONTROLADOR_OBLIGATORIO = "CONTROLADOR_OBLIGATORIO",
  ADVERADOR_OBLIGATORIO = "ADVERADOR_OBLIGATORIO",
  USO_INTERNO_OBLIGATORIO = "USO_INTERNO_OBLIGATORIO",
  EASYPARK = "EASYPARK",
  MOVILTIK = "MOVILTIK",
  NO_EXISTEN_DENUNCIAS_PENDIENTES_DE_ENVIAR_PARA_LA_FECHA_INDICADA = "NO_EXISTEN_DENUNCIAS_PENDIENTES_DE_ENVIAR_PARA_LA_FECHA_INDICADA",
  FECHA_INVALIDA = "FECHA_INVALIDA",
  ESCANEAR_MATRICULA = "ESCANEAR_MATRICULA",
  HAZ_CAPTURA_MATRICULA = "HAZ_CAPTURA_MATRICULA",
  PARA_SEGUIR_ADVERANDO_ES_NECESARIO_ANADIR_UNA_IMAGEN = "PARA_SEGUIR_ADVERANDO_ES_NECESARIO_ANADIR_UNA_IMAGEN",
  FIRMA_DE_CONTROLADOR = "FIRMA_DE_CONTROLADOR",
  BORRAR_FIRMA = "BORRAR_FIRMA",
  ES_NECESARIO_PROPORCIONAR_LA_FIRMA = "ES_NECESARIO_PROPORCIONAR_LA_FIRMA",
  LA_DENUNCIA_HA_SIDO_ADVERADA = "LA_DENUNCIA_HA_SIDO_ADVERADA",
  PDF_DENUNCIA = "PDF_DENUNCIA",
  PDF_DENUNCIA_RATIFICADA = "PDF_DENUNCIA_RATIFICADA",
  CODIGO_DE_PROVINCIA_SUMA = "CODIGO_DE_PROVINCIA_SUMA",
  CODIGO_DE_MUNICIPIO_SUMA = "CODIGO_DE_MUNICIPIO_SUMA",
  CODIGO_DE_PROVINCIA_OBLIGATORIO = "CODIGO_DE_PROVINCIA_OBLIGATORIO",
  CODIGO_DE_MUNICIPIO_OBLIGATORIO = "CODIGO_DE_MUNICIPIO_OBLIGATORIO",
  LONGITUD_DEBE_SER = "LONGITUD_DEBE_SER",
  FICHERO_KML = "FICHERO_KML",
  DENUNCIA_ANULADA = "DENUNCIA_ANULADA",
  DENUNCIA_INVALIDADA = "DENUNCIA_INVALIDADA",
  DENUNCIA_RECTIFICADA = "DENUNCIA_RECTIFICADA",
  DENUNCIA_PUESTA_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE = "DENUNCIA_PUESTA_COMO_PENDIENTE_DE_ENVIAR_A_TRAMITE",
  DENUNCIA_PUESTA_COMO_NO_ENVIADA_A_TRAMITE = "DENUNCIA_PUESTA_COMO_NO_ENVIADA_A_TRAMITE",
  CONFIGURACION_DE_LA_APP = "CONFIGURACION_DE_LA_APP",
  TIEMPO_INACTIVIDAD_DENUNCIA_MINUTOS = "TIEMPO_INACTIVIDAD_DENUNCIA_MINUTOS",
  TIEMPO_INACTIVIDAD_MOVIMIENTO_MINUTOS = "TIEMPO_INACTIVIDAD_MOVIMIENTO_MINUTOS",
  TIEMPO_INACTIVIDAD_DENUNCIA_OBLIGATORIO = "TIEMPO_INACTIVIDAD_DENUNCIA_OBLIGATORIO",
  TIEMPO_INACTIVIDAD_MOVIMIENTO_OBLIGATORIO = "TIEMPO_INACTIVIDAD_MOVIMIENTO_OBLIGATORIO",
  DEBE_SER_UN_VALOR_POSITIVO = "DEBE_SER_UN_VALOR_POSITIVO",
  PERMISOS_UBICACION_ANDROID = "PERMISOS_UBICACION_ANDROID",
  PERMISOS_UBICACION_IOS = "PERMISOS_UBICACION_IOS",
  IR_A_AJUSTES = "IR_A_AJUSTES",
  DATOS_CONDUCTOR = "DATOS_CONDUCTOR",
  TIPO_CONDUCTOR = "TIPO_CONDUCTOR",
  RAZON_DE_ANULACION = "RAZON_DE_ANULACION",
  FOTOGRAFIA_DE_INVALIDACION = "FOTOGRAFIA_DE_INVALIDACION",
  LEYES = "LEYES",
  NO_EXISTEN_LEYES = "NO_EXISTEN_LEYES",
  ELIMINAR_LEYES = "ELIMINAR_LEYES",
  DESCUENTO = "DESCUENTO",
  NUEVA_LEY = "NUEVA_LEY",
  DESCUENTO_OBLIGATORIO = "DESCUENTO_OBLIGATORIO",
  LEY_MODIFICADA_CORRECTAMENTE = "LEY_MODIFICADA_CORRECTAMENTE",
  LEY_CREADA_CORRECTAMENTE = "LEY_CREADA_CORRECTAMENTE",
  MOTIVOS_DE_PLAZAS_PERDIDAS = "MOTIVOS_DE_PLAZAS_PERDIDAS",
  NO_EXISTEN_MOTIVOS_DE_PLAZAS_PERDIDAS = "NO_EXISTEN_MOTIVOS_DE_PLAZAS_PERDIDAS",
  MOTIVO_DE_PLAZA_PERDIDA = "MOTIVO_DE_PLAZA_PERDIDA",
  NUEVO_MOTIVO_DE_PLAZA_PERDIDA = "NUEVO_MOTIVO_DE_PLAZA_PERDIDA",
  MOTIVO_DE_PLAZA_PERDIDA_MODIFICADO_CORRECTAMENTE = "MOTIVO_DE_PLAZA_PERDIDA_MODIFICADO_CORRECTAMENTE",
  MOTIVO_DE_PLAZA_PERDIDA_CREADO_CORRECTAMENTE = "MOTIVO_DE_PLAZA_PERDIDA_CREADO_CORRECTAMENTE",
  ELIMINAR_MOTIVOS_DE_PLAZAS_PERDIDAS = "ELIMINAR_MOTIVOS_DE_PLAZAS_PERDIDAS",
  DENUNCIAS_ENVIADAS = "DENUNCIAS_ENVIADAS",
  FECHA_Y_HORA_REFERENCIA_ANULACION_APP = "FECHA_Y_HORA_REFERENCIA_ANULACION_APP",
  RECLAMAR = "RECLAMAR",
  RECLAMADA = "RECLAMADA",
  DEVOLVER_ANULACION = "DEVOLVER_ANULACION",
  DENUNCIA_RECLAMADA = "DENUNCIA_RECLAMADA",
  IMPORTE_ANULACION_DEVUELTO = "IMPORTE_ANULACION_DEVUELTO",
  DENUNCIA = "DENUNCIA",
  CREADA_CORRECTAMENTE = "CREADA_CORRECTAMENTE",
  IMPRIMIR = "IMPRIMIR",
  NUEVA_DENUNCIA = "NUEVA_DENUNCIA",
  MOTIVO_DE_INFRACCION = "MOTIVO_DE_INFRACCION",
  OBLIGATORIO_ANYADIR_MINIMO_X_IMAGENES = "OBLIGATORIO_ANYADIR_MINIMO_X_IMAGENES",
  ANYADIR_FOTO = "ANYADIR_FOTO",
  DATOS_DENUNCIADO = "DATOS_DENUNCIADO",
  MAXIMO_200_CARACTERES = "MAXIMO_200_CARACTERES",
  ANYADIR_IMAGEN_Y_CONTINUAR = "ANYADIR_IMAGEN_Y_CONTINUAR",
  DESEAS_ANYADIR_LA_SIGUIENTE_IMAGEN = "DESEAS_ANYADIR_LA_SIGUIENTE_IMAGEN",
  LLEVAS = "LLEVAS",
  FINALIZAR_DENUNCIA = "FINALIZAR_DENUNCIA",
  NOMBRE_Y_APELLIDOS = "NOMBRE_Y_APELLIDOS",
  DNI = "DNI",
  PROPIETARIO_CONDUCTOR = "PROPIETARIO_CONDUCTOR",
  REGISTRO_DATOS_DENUNCIADO = "REGISTRO_DATOS_DENUNCIADO",
  PROPIETARIO = "PROPIETARIO",
  CONDUCTOR = "CONDUCTOR",
  DESEAS_DESCARTAR_DENUNCIA = "DESEAS_DESCARTAR_DENUNCIA",
  SE_PERDERAN_LOS_CAMBIOS = "SE_PERDERAN_LOS_CAMBIOS",
  FIRMA_CONTROLADOR = "FIRMA_CONTROLADOR",
  DEBES_SELECCIONAR_PRIMERO_ZONA_Y_RUTA = "DEBES_SELECCIONAR_PRIMERO_ZONA_Y_RUTA",
  ANYADIR_FIRMA_DIGITAL = "ANYADIR_FIRMA_DIGITAL",
  NUM = "NUM",
  NO_SE_HA_ENCONTRADO_LA_DIRECCION = "NO_SE_HA_ENCONTRADO_LA_DIRECCION",
  FALTAN_CAMPOS = "FALTAN_CAMPOS",
  LA_DENUNCIA_DEBE_ESTAR_FIRMADA = "LA_DENUNCIA_DEBE_ESTAR_FIRMADA",
  GESTION_DE_INCIDENCIAS = "GESTION_DE_INCIDENCIAS",
  REPORTAR = "REPORTAR",
  RECAUDACIONES = "RECAUDACIONES",
  NUEVA_RECAUDACION = "NUEVA_RECAUDACION",
  BUSCAR_POR_CODIGO = "BUSCAR_POR_CODIGO",
  ULTIMAS_INCIDENCIAS = "ULTIMAS_INCIDENCIAS",
  SIN_OBSERVACIONES = "SIN_OBSERVACIONES",
  SIN_INCIDENCIAS = "SIN_INCIDENCIAS",
  SIN_RECAUDACIONES = "SIN_RECAUDACIONES",
  SIN_PLAZAS_PERDIDAS = "SIN_PLAZAS_PERDIDAS",
  REPORTAR_INCIDENCIA_EN_PARQUIMETRO = "REPORTAR_INCIDENCIA_EN_PARQUIMETRO",
  CODIGO_DEL_PARQUIMETRO = "CODIGO_DEL_PARQUIMETRO",
  TIPO_DE_PROBLEMA_DETECTADO = "TIPO_DE_PROBLEMA_DETECTADO",
  INCIDENCIA_EN_PARQUIMETRO_REPORTADA_CORRECTAMENTE = "INCIDENCIA_EN_PARQUIMETRO_REPORTADA_CORRECTAMENTE",
  IMPORTE_EN_E_QUE_SE_RECAUDA = "IMPORTE_EN_E_QUE_SE_RECAUDA",
  CODIGO_DE_LA_BRIDA = "CODIGO_DE_LA_BRIDA",
  RECAUDACION_CREADA_CORRECTAMENTE = "RECAUDACION_CREADA_CORRECTAMENTE",
  REPORTAR_INCIDENCIA_EN_PLAZAS_PERDIDAS = "REPORTAR_INCIDENCIA_EN_PLAZAS_PERDIDAS",
  N = "N",
  NUMERO_DE_PLAZAS_AFECTADAS = "NUMERO_DE_PLAZAS_AFECTADAS",
  HORAS_QUE_ESTARAN_OCUPADAS = "HORAS_QUE_ESTARAN_OCUPADAS",
  INCIDENCIA_EN_PLAZAS_PERDIDAS_CREADA_CORRECTAMENTE = "INCIDENCIA_EN_PLAZAS_PERDIDAS_CREADA_CORRECTAMENTE",
  BRIDA_PLACEHOLDER = "BRIDA_PLACEHOLDER",
  CURRENCY_PLACEHOLDER = "CURRENCY_PLACEHOLDER",
  CODIGO_PLACEHOLDER = "CODIGO_PLACEHOLDER",
  N_PLAZAS_PLACEHOLDER = "N_PLAZAS_PLACEHOLDER",
  BLUETOOTH = "BLUETOOTH",
  CONECTADO_A_PLACEHOLDER = "CONECTADO_A_PLACEHOLDER",
  SIN_CONEXION = "SIN_CONEXION",
  CONECTANDO_A_PLACEHOLDER = "CONECTANDO_A_PLACEHOLDER",
  ULTIMAS_IMPRESORAS_CONECTADAS = "ULTIMAS_IMPRESORAS_CONECTADAS",
  CONECTADO = "CONECTADO",
  ESCANEAR_NUEVOS_DISPOSITIVOS = "ESCANEAR_NUEVOS_DISPOSITIVOS",
  DISPOSITIVOS_ENCONTRADOS = "DISPOSITIVOS_ENCONTRADOS",
  ERROR_DE_CONEXION = "ERROR_DE_CONEXION",
  BLUETOOTH_DESACTIVADO = "BLUETOOTH_DESACTIVADO",
  EL_DISPOSITIVO_NO_DISPONE_DE_BLUETOOTH = "EL_DISPOSITIVO_NO_DISPONE_DE_BLUETOOTH",
  PERMISOS_RECHAZADOS = "PERMISOS_RECHAZADOS",
  DESEAS_ELIMINAR_PLACEHOLDER = "DESEAS_ELIMINAR_PLACEHOLDER",
  ELIMINAR_IMPRESORA = "ELIMINAR_IMPRESORA",
  AUN_NO_TE_HAS_CONECTADO_A_NINGUNA_IMPRESORA = "AUN_NO_TE_HAS_CONECTADO_A_NINGUNA_IMPRESORA",
  DISPOSITIVO_SIN_NOMBRE = "DISPOSITIVO_SIN_NOMBRE",
  CONECTANDO_BLUETOOTH_A = "CONECTANDO_BLUETOOTH_A",
  ESTE_DISPOSITIVO_NO_ESTA_RECONOCIDO_COMO_IMPRESORA = "ESTE_DISPOSITIVO_NO_ESTA_RECONOCIDO_COMO_IMPRESORA",
  NO_SE_HAN_PODIDO_ENCONTRAR_LAS_IMPRESORAS_EMPAREJADAS_A_ESTE_DISPOSITIVO = "NO_SE_HAN_PODIDO_ENCONTRAR_LAS_IMPRESORAS_EMPAREJADAS_A_ESTE_DISPOSITIVO",
  NO_SE_HA_PODIDO_ELIMINAR_LA_IMPRESORA = "NO_SE_HA_PODIDO_ELIMINAR_LA_IMPRESORA",
  AYUDANTE = "AYUDANTE",
  ID_COLECTA = "ID_COLECTA",
  IMAGEN = "IMAGEN",
  NUMERO_DE_ANULACION = "NUMERO_DE_ANULACION",
  FECHA_DE_EXPIRACION = "FECHA_DE_EXPIRACION",
  PANTALLA_DATOS_DENUNCIADO = "PANTALLA_DATOS_DENUNCIADO",
  USUARIO_MOVILTIK = "USUARIO_MOVILTIK",
  CONTRASENA_MOVILTIK = "CONTRASENA_MOVILTIK",
  INTEGRACION_CON_OTROS_SISTEMAS = "INTEGRACION_CON_OTROS_SISTEMAS",
  USUARIO_OBLIGATORIO = "USUARIO_OBLIGATORIO",
  NO_SE_HA_PODIDO_ESTABLECER_UNA_CONEXION_CON_EL_SERVIDOR = "NO_SE_HA_PODIDO_ESTABLECER_UNA_CONEXION_CON_EL_SERVIDOR",
  ESTE_CAMPO_NO_PUEDE_ESTAR_VACIO = "ESTE_CAMPO_NO_PUEDE_ESTAR_VACIO",
  REINTENTAR = "REINTENTAR",
  NO_HAY_DENUNCIAS = "NO_HAY_DENUNCIAS",
  PULSE_ATRAS_DE_NUEVO_PARA_CERRAR_APP = "PULSE_ATRAS_DE_NUEVO_PARA_CERRAR_APP",
  MIS_DENUNCIAS = "MIS_DENUNCIAS",
  BUSQUEDA = "BUSQUEDA",
  ELIGE_PRIMERO_UNA_MARCA_DE_COCHE = "ELIGE_PRIMERO_UNA_MARCA_DE_COCHE",
  LA_MATRICULA_SOLO_PUEDE_CONTENER_LETRAS_MAYUSCULAS_Y_NUMEROS = "LA_MATRICULA_SOLO_PUEDE_CONTENER_LETRAS_MAYUSCULAS_Y_NUMEROS",
  CONFIRMAR = "CONFIRMAR",
  FECHA_EXCESO_DE_TICKET = "FECHA_EXCESO_DE_TICKET",
  TIEMPO_EXCEDIDO = "TIEMPO_EXCEDIDO",
  MOTIVO_DE_LA_INVALIDACION_DE_LA_DENUNCIA = "MOTIVO_DE_LA_INVALIDACION_DE_LA_DENUNCIA",
  DESCRIPCION_DEL_MOTIVO = "DESCRIPCION_DEL_MOTIVO",
  PARA_SEGUIR_CON_LA_INVALIDACION_ES_NECESARIO_ANYADIR_UNA_IMAGEN = "PARA_SEGUIR_CON_LA_INVALIDACION_ES_NECESARIO_ANYADIR_UNA_IMAGEN",
  MOTIVO_DE_LA_INVALIDACION = "MOTIVO_DE_LA_INVALIDACION",
  INVALIDACION_TRAMITADA_CORRECTAMENTE = "INVALIDACION_TRAMITADA_CORRECTAMENTE",
  OCUPADAS_PLACEHOLDER = "OCUPADAS_PLACEHOLDER",
  IMAGEN_ADJUNTA = "IMAGEN_ADJUNTA",
  SIN_IMAGEN_ADJUNTA = "SIN_IMAGEN_ADJUNTA",
  COLECTA_PLACEHOLDER = "COLECTA_PLACEHOLDER",
  AYUDANTE_PLACEHOLDER = "AYUDANTE_PLACEHOLDER",
  AYUDANTE_NO_ESPECIFICADO = "AYUDANTE_NO_ESPECIFICADO",
  CODIGO_DE_COLECTA = "CODIGO_DE_COLECTA",
  FECHA_HORA_DE_COLETA = "FECHA_HORA_DE_COLETA",
  IMPORTE_RECAUDADO_EN_MONEDAS = "IMPORTE_RECAUDADO_EN_MONEDAS",
  AYUDANTE_DE_RECAUDACION = "AYUDANTE_DE_RECAUDACION",
  FOTO_DE_LA_TIRA_DE_RECAUDACION = "FOTO_DE_LA_TIRA_DE_RECAUDACION",
  BUSCAR_POR_CODIGO_U_OBSERVACIONES = "BUSCAR_POR_CODIGO_U_OBSERVACIONES",
  DENUNCIA_YA_INVALIDADA = "DENUNCIA_YA_INVALIDADA",
  NO_PUEDES_ADVERAR_UNA_DENUNCIA_CREADA_POR_TI = "NO_PUEDES_ADVERAR_UNA_DENUNCIA_CREADA_POR_TI",
  FORMATO_DE_MATRICULA_NO_RECONOCIDA_DESEA_CONTINUAR = "FORMATO_DE_MATRICULA_NO_RECONOCIDA_DESEA_CONTINUAR",
  CONTINUAR = "CONTINUAR",
  NO_SE_HA_PODIDO_IMPRIMIR_LA_DENUNCIA = "NO_SE_HA_PODIDO_IMPRIMIR_LA_DENUNCIA",
  DEBES_CONECTARTE_PRIMERO_A_UNA_IMPRESORA = "DEBES_CONECTARTE_PRIMERO_A_UNA_IMPRESORA",
  HA_OCURRIDO_UN_ERROR_AL_INTENTAR_GUARDAR_LA_FOTO = "HA_OCURRIDO_UN_ERROR_AL_INTENTAR_GUARDAR_LA_FOTO",
  IMPRIMIR_DENUNCIA = "IMPRIMIR_DENUNCIA",
  POR_FAVOR_ANADE_TU_FIRMA = "POR_FAVOR_ANADE_TU_FIRMA",
  A_CONTINUACION_PULSA_EL_BOTON_PARA_IMPRIMIR_DENUNCIA = "A_CONTINUACION_PULSA_EL_BOTON_PARA_IMPRIMIR_DENUNCIA",
  PARA_SEGUIR_CON_LA_ADEVERACION_ES_NECESARIO_REALIZAR_UNA_FOTO_FORMATTED = "PARA_SEGUIR_CON_LA_ADEVERACION_ES_NECESARIO_REALIZAR_UNA_FOTO_FORMATTED",
  POR_FAVOR_ANADE_LA_FOTO = "POR_FAVOR_ANADE_LA_FOTO",
  NO_PUEDES_ADVERAR_UNA_DENUNCIA_YA_INVALIDADA = "NO_PUEDES_ADVERAR_UNA_DENUNCIA_YA_INVALIDADA",
  NO_HAY_NINGUNA_IMPRESORA_EMPAREJADA_AL_DISPOSITIVO = "NO_HAY_NINGUNA_IMPRESORA_EMPAREJADA_AL_DISPOSITIVO",
  NO_SE_HA_PODIDO_CONECTAR_A_LA_ULTIMA_IMPRESORA_EMPAREJADA_AL_DISPOSITIVO = "NO_SE_HA_PODIDO_CONECTAR_A_LA_ULTIMA_IMPRESORA_EMPAREJADA_AL_DISPOSITIVO",
  NO_SE_HA_PODIDO_DESCONECTAR_LA_IMPRESORA_VINCULADA_AL_DISPOSITIVO = "NO_SE_HA_PODIDO_DESCONECTAR_LA_IMPRESORA_VINCULADA_AL_DISPOSITIVO",
  BUSCANDO_ULTIMA_IMPRESORA_USADA = "BUSCANDO_ULTIMA_IMPRESORA_USADA",
  CONECTANDO_ULTIMA_IMPRESORA_USADA = "CONECTANDO_ULTIMA_IMPRESORA_USADA",
  IMPRIMIENDO = "IMPRIMIENDO",
  DESCONECTANDO_IMPRESORA = "DESCONECTANDO_IMPRESORA",
  CONTACTO = "CONTACTO",
  AVISO_LEGAL = "AVISO_LEGAL",
  OBLIGATORIO_ANYADIR_MINIMO_1_IMAGEN = "OBLIGATORIO_ANYADIR_MINIMO_1_IMAGEN",
  DEBES_SELECCIONAR_PRIMERO_UNA_LEY_INCUMPLIDA = "DEBES_SELECCIONAR_PRIMERO_UNA_LEY_INCUMPLIDA",
  NO_HAY_NINGUNA_IMPRESORA_VINCULADA_AL_DISPOSITIVO_DESEAS_IR_A_LOS_AJUSTES = "NO_HAY_NINGUNA_IMPRESORA_VINCULADA_AL_DISPOSITIVO_DESEAS_IR_A_LOS_AJUSTES",
  INVALIDADA = "INVALIDADA",
  NO_SE_HA_PODIDO_PONER_LA_ALTURA_POR_DEFECTO_DE_LA_ETIQUETA = "NO_SE_HA_PODIDO_PONER_LA_ALTURA_POR_DEFECTO_DE_LA_ETIQUETA",
  ERROR = "ERROR",
  ERROR_CERT_NO_AUTORIZADO = "ERROR_CERT_NO_AUTORIZADO",
  ERROR_CERT_CERTIFICADO_NO_ENCONTRADO = "ERROR_CERT_CERTIFICADO_NO_ENCONTRADO",
  ERROR_CERT_CERTIFICADO_EXPIRADO = "ERROR_CERT_CERTIFICADO_EXPIRADO",
  ERROR_INTERNO_DEL_SERVIDOR = "ERROR_INTERNO_DEL_SERVIDOR",
  CERTIFICADO_DIGITAL = "CERTIFICADO_DIGITAL",
  INTRODUCE_LA_CONTRASEÑA_DE_TU_CERTIFICADO_DIGITAL = "INTRODUCE_LA_CONTRASEÑA_DE_TU_CERTIFICADO_DIGITAL",
  ACCESO_NO_PERMITIDO = "ACCESO_NO_PERMITIDO",
  ANULACION_DENUNCIAS_DE_FICHERO = "ANULACION_DENUNCIAS_DE_FICHERO",
  FICHERO_CSV = "FICHERO_CSV",
  FICHERO_CSV_OBLIGATORIO = "FICHERO_CSV_OBLIGATORIO",
  ANULACION_DENUNCIAS_DE_FICHERO_REALIZADA_CORRECTAMENTE = "ANULACION_DENUNCIAS_DE_FICHERO_REALIZADA_CORRECTAMENTE",
  LOGS_CANCELACION_DENUNCIAS = "LOGS_CANCELACION_DENUNCIAS",
  INFORME_ANULACION_DENUNCIAS_DE_FICHERO = "INFORME_ANULACION_DENUNCIAS_DE_FICHERO",
  ORDEN = "ORDEN",
  ORDEN_OBLIGATORIO = "ORDEN_OBLIGATORIO",
  EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5 = "EL_ORDEN_HA_DE_SER_ENTRE_0_Y_5",
  NO_EXISTEN_DENUNCIAS_PARA_EXPORTAR = "NO_EXISTEN_DENUNCIAS_PARA_EXPORTAR",
  INTENTAS_EXPORTAR_MAS_DE_10000_DENUNCIAS = "INTENTAS_EXPORTAR_MAS_DE_10000_DENUNCIAS",
  FECHA_Y_HORA_DE_RATIFICACION = "FECHA_Y_HORA_DE_RATIFICACION",
  INFORMACION_LEGAL = "INFORMACION_LEGAL",
  ACTUALIZAR = "ACTUALIZAR",
  NO_EXISTE_NINGUN_CERTIFICADO_DESEA_CREAR_UN_CERTIFICADO = "NO_EXISTE_NINGUN_CERTIFICADO_DESEA_CREAR_UN_CERTIFICADO",
  CREAR_CERTIFICADO = "CREAR_CERTIFICADO",
  ACTUALIZAR_CERTIFICADO = "ACTUALIZAR_CERTIFICADO",
  CERTIFICADO = "CERTIFICADO",
  ELIMINAR_CERTIFICADO_DIGITAL = "ELIMINAR_CERTIFICADO_DIGITAL",
  ESTAS_A_PUNTO_DE_ELIMINAR_TU_CERTIFICADO_DIGITAL_DESEAS_CONTINUAR = "ESTAS_A_PUNTO_DE_ELIMINAR_TU_CERTIFICADO_DIGITAL_DESEAS_CONTINUAR",
  CERTIFICADO_OBLIGATORIO = "CERTIFICADO_OBLIGATORIO",
  SOLO_SE_ADMITEN_FICHEROS_DE_TIPO = "SOLO_SE_ADMITEN_FICHEROS_DE_TIPO",
  CERTIFICADO_DIGITAL_CREADO_CORRECTAMENTE = "CERTIFICADO_DIGITAL_CREADO_CORRECTAMENTE",
  CERTIFICADO_DIGITAL_ACTUALIZADO_CORRECTAMENTE = "CERTIFICADO_DIGITAL_ACTUALIZADO_CORRECTAMENTE",
  EL_CERTIFICADO_NO_ES_VALIDO = "EL_CERTIFICADO_NO_ES_VALIDO",
  LA_CONTRASENA_DEL_CERTIFICADO_ES_INCORRECTA = "LA_CONTRASENA_DEL_CERTIFICADO_ES_INCORRECTA",
  CERTIFICADO_DIGITAL_ELIMINADO_CORRECTAMENTE = "CERTIFICADO_DIGITAL_ELIMINADO_CORRECTAMENTE",
  CERTIFICADO_VALIDO = "CERTIFICADO_VALIDO",
  CERTIFICADO_INVALIDO = "CERTIFICADO_INVALIDO",
  EL_CERTIFICADO_CADUCO_EL = "EL_CERTIFICADO_CADUCO_EL",
  EL_CERTIFICADO_CADUCA_EL = "EL_CERTIFICADO_CADUCA_EL",
  CERTIFICADO_VALIDO_HASTA_EL = "CERTIFICADO_VALIDO_HASTA_EL",
  NO_EXISTE_FIRMA_DIGITAL = "NO_EXISTE_FIRMA_DIGITAL",
  NO_SE_HA_PODIDO_CONSEGUIR_LA_UBICACION_ACTUAL = "NO_SE_HA_PODIDO_CONSEGUIR_LA_UBICACION_ACTUAL",
  ESTAS_FUERA_DE_LA_ZONA_DE_EXPLOTACION = "ESTAS_FUERA_DE_LA_ZONA_DE_EXPLOTACION",
  ESTAS_DEMASIADO_LEJOS_DEL_PUNTO_DE_DENUNCIA_ORIGINAL = "ESTAS_DEMASIADO_LEJOS_DEL_PUNTO_DE_DENUNCIA_ORIGINAL",
  ESTAS_COLOCANDO_UNA_DENUNCIA_FUERA_DE_LA_ZONA_ASIGNADA_A_TU_EXPLOTACION = "ESTAS_COLOCANDO_UNA_DENUNCIA_FUERA_DE_LA_ZONA_ASIGNADA_A_TU_EXPLOTACION",
  RESUMEN_DE_LA_DENUNCIA = "RESUMEN_DE_LA_DENUNCIA",
  EDITAR_CAMPOS = "EDITAR_CAMPOS",
  RESUMEN_DENUNCIA = "RESUMEN_DENUNCIA",
  NUMERO_DE_FICHERO_DE_EXPORTACION_SUMA = "NUMERO_DE_FICHERO_DE_EXPORTACION_SUMA",
  DEBE_SER_UN_VALOR_SUPERIOR_A_CERO = "DEBE_SER_UN_VALOR_SUPERIOR_A_CERO",
  RADIO_DENUNCIA_ADVERACION_OBLIGATORIO = "RADIO_DENUNCIA_ADVERACION_OBLIGATORIO",
  TIEMPO_FINALIZACION_JORNADA_OBLIGATORIO = "TIEMPO_FINALIZACION_JORNADA_OBLIGATORIO",
  RADIO_DENUNCIA_ADVERACION_METROS = "RADIO_DENUNCIA_ADVERACION_METROS",
  TIEMPO_FINALIZACION_JORNADA_MINUTOS = "TIEMPO_FINALIZACION_JORNADA_MINUTOS",
  GESTION_DE_ANULACION = "GESTION_DE_ANULACION",
  TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_MINUTOS = "TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_MINUTOS",
  TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_OBLIGATORIO = "TIEMPO_LIMITE_DE_CREACION_DE_UNA_DENUNCIA_OBLIGATORIO",
  LA_DENUNCIA_NO_TIENE_UNA_UBICACION_ASIGNADA = "LA_DENUNCIA_NO_TIENE_UNA_UBICACION_ASIGNADA",
  LICENCIA_RECONOCIMIENTO_MATRICULAS = "LICENCIA_RECONOCIMIENTO_MATRICULAS",
  CORREO_ELECTRONICO = "CORREO_ELECTRONICO",
  ESTAS_FUERA_DEL_HORARIO_DE_LA_JORNADA_SE_CERRARA_LA_SESION_EN_X_MINUTOS = "ESTAS_FUERA_DEL_HORARIO_DE_LA_JORNADA_SE_CERRARA_LA_SESION_EN_X_MINUTOS",
  LUNES = "LUNES",
  MARTES = "MARTES",
  MIERCOLES = "MIERCOLES",
  JUEVES = "JUEVES",
  VIERNES = "VIERNES",
  SABADO = "SABADO",
  DOMINGO = "DOMINGO",
  HORARIO_TARDE = "HORARIO_TARDE",
  HORARIO_MANANA = "HORARIO_MANANA",
  MANANA_HORA_INICIO = "MANANA_HORA_INICIO",
  MANANA_HORA_FIN = "MANANA_HORA_FIN",
  TARDE_HORA_INICIO = "TARDE_HORA_INICIO",
  TARDE_HORA_FIN = "TARDE_HORA_FIN",
  DIAS_DE_LA_SEMANA = "DIAS_DE_LA_SEMANA",
  CONTROL_DE_LA_JORNADA = "CONTROL_DE_LA_JORNADA",
  NO_HAY_HORARIOS_INTRODUCIDOS = "NO_HAY_HORARIOS_INTRODUCIDOS",
  HAY_DIAS_DE_LA_SEMANA_SELECCIONADA = "HAY_DIAS_DE_LA_SEMANA_SELECCIONADA",
  HORARIO_DE_MANANA_INCOMPLETO = "HORARIO_DE_MANANA_INCOMPLETO",
  EL_HORARIO_DE_INICIO_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_DIA_DE_FIN_DE_MANANA = "EL_HORARIO_DE_INICIO_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_DIA_DE_FIN_DE_MANANA",
  HORARIO_DE_TARDE_INCOMPLETO = "HORARIO_DE_TARDE_INCOMPLETO",
  EL_HORARIO_DE_INICIO_DE_TARDE_DEBE_SER_INFERIOR_AL_DE_FIN_DE_TARDE = "EL_HORARIO_DE_INICIO_DE_TARDE_DEBE_SER_INFERIOR_AL_DE_FIN_DE_TARDE",
  EL_HORARIO_DE_FIN_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_INICIO_DE_TARDE = "EL_HORARIO_DE_FIN_DE_MANANA_DEBE_SER_INFERIOR_AL_DE_INICIO_DE_TARDE",
  EXISTEN_DIAS_REPETIDOS = "EXISTEN_DIAS_REPETIDOS",
  CONFIGURACION_GENERAL = "CONFIGURACION_GENERAL",
  CONFIGURACION_APP = "CONFIGURACION_APP",
  VERSION_MINIMA = "VERSION_MINIMA",
  URL_ANDROID = "URL_ANDROID",
  URL_IOS = "URL_IOS",
  CONFIGURACION_DE_LA_APP_MODIFICADA_CORRECTAMENTE = "CONFIGURACION_DE_LA_APP_MODIFICADA_CORRECTAMENTE",
  VERSION_OBLIGATORIA = "VERSION_OBLIGATORIA",
  URL_ANDROID_OBLIGATORIA = "URL_ANDROID_OBLIGATORIA",
  URL_IOS_OBLIGATORIA = "URL_IOS_OBLIGATORIA",
  FORMATO_DE_LA_VERSION_INCORRECTO = "FORMATO_DE_LA_VERSION_INCORRECTO",
  HAY_UNA_NUEVA_VERSION_DE_LA_APP_DISPONIBLE = "HAY_UNA_NUEVA_VERSION_DE_LA_APP_DISPONIBLE",
  INCIDENCIA_CERRADA = "INCIDENCIA_CERRADA",
  INCIDENCIA_ABIERTA = "INCIDENCIA_ABIERTA",
  CERRAR_INCIDENCIA = "CERRAR_INCIDENCIA",
  DESEAS_CERRAR_LA_INCIDENCIA = "DESEAS_CERRAR_LA_INCIDENCIA",
  ULTIMO_ENVIO = "ULTIMO_ENVIO",
  TOTALIZACION_DENUNCIAS = "TOTALIZACION_DENUNCIAS",
  INCIDENCIAS_PKM_Y_PLAZAS_PERDIDAS = "INCIDENCIAS_PKM_Y_PLAZAS_PERDIDAS",
  LOTE = "LOTE",
  PERIODO_DENUNCIAS = "PERIODO_DENUNCIAS",
  TOTAL_DENUNCIAS_TRAMITADAS = "TOTAL_DENUNCIAS_TRAMITADAS",
  DIA_ACTUAL = "DIA_ACTUAL",
  SEMANA_ACTUAL = "SEMANA_ACTUAL",
  MES_ACTUAL = "MES_ACTUAL",
  ANO_ACTUAL = "ANO_ACTUAL",
  DIA_ANTERIOR = "DIA_ANTERIOR",
  SEMANA_ANTERIOR = "SEMANA_ANTERIOR",
  MES_ANTERIOR = "MES_ANTERIOR",
  ANO_ANTERIOR = "ANO_ANTERIOR",
  FECHA_ULTIMA_RECAUDACION = "FECHA_ULTIMA_RECAUDACION",
  TOTAL_INCIDENCIAS_REPORTADAS_SEMANA_ACTUAL = "TOTAL_INCIDENCIAS_REPORTADAS_SEMANA_ACTUAL",
  TOTAL_PLAZAS_PERDIDAS_MES_ACTUAL = "TOTAL_PLAZAS_PERDIDAS_MES_ACTUAL",
  NO_SE_HA_PODIDO_CERRAR_LA_JORNADA_DE_TRABAJO = "NO_SE_HA_PODIDO_CERRAR_LA_JORNADA_DE_TRABAJO",
  CODIGO_ALZIRA = "CODIGO_ALZIRA",
  CONTACTOS = "CONTACTOS",
  NO_HAY_USUARIOS_DISPONIBLES = "NO_HAY_USUARIOS_DISPONIBLES",
  CARGANDO_MENSAJES_ANTERIORES = "CARGANDO_MENSAJES_ANTERIORES",
  X_MENSAJE_NO_LEIDO = "X_MENSAJE_NO_LEIDO",
  X_MENSAJES_NO_LEIDOS = "X_MENSAJES_NO_LEIDOS",
  EQUIPO_PAVAPARK = "EQUIPO_PAVAPARK",
  EMAILS_NOTIFICACION_GRUA = "EMAILS_NOTIFICACION_GRUA",
  EMAILS_SOS = "EMAILS_SOS",
  FORMATO_INCORRECTO_EMAILS = "FORMATO_INCORRECTO_EMAILS",
  TIPO_DE_INCIDENCIA = "TIPO_DE_INCIDENCIA",
  HISTORICO_DE_EXPORTACIONES = "HISTORICO_DE_EXPORTACIONES",
  NO_EXISTEN_EXPORTACIONES = "NO_EXISTEN_EXPORTACIONES",
  DESCARGAR_FICHERO = "DESCARGAR_FICHERO",
  PULSA_LA_NOTIFICACION_SI_TIENES_PROBLEMAS = "PULSA_LA_NOTIFICACION_SI_TIENES_PROBLEMAS",
  Y_NECESITAS_AYUDA = "Y_NECESITAS_AYUDA",
  TE_ENCUENTRAS_EN_PELIGRO = "TE_ENCUENTRAS_EN_PELIGRO",
  ENVIA_UN_AVISO_DE_AYUDA_A_TUS_COMPANYEROS_MAS_CERCANOS = "ENVIA_UN_AVISO_DE_AYUDA_A_TUS_COMPANYEROS_MAS_CERCANOS",
  X_HA_ACTIVADO_UNA_ALARMA_A_LAS_X = "X_HA_ACTIVADO_UNA_ALARMA_A_LAS_X",
  ALARMA_SOS = "ALARMA_SOS",
  VER_UBICACION = "VER_UBICACION",
  BEEPARK = "BEEPARK",
  EXPLOTACION_ID = "EXPLOTACION_ID",
  EXPLOTACION_ID_OBLIGATORIO = "EXPLOTACION_ID_OBLIGATORIO",
  TOKEN = "TOKEN",
  TOKEN_OBLIGATORIO = "TOKEN_OBLIGATORIO",
  PROCESOS_RECAUDACION = "PROCESOS_RECAUDACION",
  PROCESO_RECAUDACION = "PROCESO_RECAUDACION",
  NO_EXISTEN_PROCESOS_DE_RECAUDACION = "NO_EXISTEN_PROCESOS_DE_RECAUDACION",
  ELIMINAR_PROCESOS_RECAUDACION = "ELIMINAR_PROCESOS_RECAUDACION",
  IMPORTE_REAL = "IMPORTE_REAL",
  CAMBIAR = "CAMBIAR",
  INTRODUZCA_IMPORTE_TOTAL_CLOUD_PARQUIMETRO = "INTRODUZCA_IMPORTE_TOTAL_CLOUD_PARQUIMETRO",
  REVISE_IMPORTES_INTRODUCIDOS = "REVISE_IMPORTES_INTRODUCIDOS",
  TOTALIZAR_DE_NUEVO = "TOTALIZAR_DE_NUEVO",
  INTRODUZCA_MOTIVO_DESFASE_DINERO = "INTRODUZCA_MOTIVO_DESFASE_DINERO",
  DETALLE_MOTIVO_DESFASE = "DETALLE_MOTIVO_DESFASE",
  INTRODUZCA_IMPORTE = "INTRODUZCA_IMPORTE",
  INTRODUZCA_IMPORTE_REAL = "INTRODUZCA_IMPORTE_REAL",
  SELECCIONAR_COLECTAS_PARQUIMETROS = "SELECCIONAR_COLECTAS_PARQUIMETROS",
  COLECTAS_PARQUIMETRO = "COLECTAS_PARQUIMETRO",
  NO_EXISTEN_COLECTAS_PARQUIMETROS = "NO_EXISTEN_COLECTAS_PARQUIMETROS",
  ERROR_SUMA_COLECTAS = "ERROR_SUMA_COLECTAS",
  SE_HA_PRODUCIDO_UN_ERROR_AL_ENVIAR_PROCESOS_RECAUDACION = "SE_HA_PRODUCIDO_UN_ERROR_AL_ENVIAR_PROCESOS_RECAUDACION",
  PROCESOS_RECAUDACION_ENVIADOS_CORRECTAMENTE = "PROCESOS_RECAUDACION_ENVIADOS_CORRECTAMENTE",
  SELECCIONE_LOS_PROCESOS_RECAUDACION = "SELECCIONE_LOS_PROCESOS_RECAUDACION",
  UMBRAL_ALERTA = "UMBRAL_ALERTA",
  DESVIACION = "DESVIACION",
  MENSAJE_ALERTA = "MENSAJE_ALERTA",
  COLECTAS_PARQUIMETROS_ANADIDAS_CORRECTAMENTE = "COLECTAS_PARQUIMETROS_ANADIDAS_CORRECTAMENTE",
  ENVIAR_PROCESOS_RECAUDACION = "ENVIAR_PROCESOS_RECAUDACION",
  ESTA_SEGURO_DE_QUE_QUIERE_ENVIAR_ESTOS_PROCESOS_RECAUDACION = "ESTA_SEGURO_DE_QUE_QUIERE_ENVIAR_ESTOS_PROCESOS_RECAUDACION",
  INTRODUCIR_MATRICULA_POR_VOZ = "INTRODUCIR_MATRICULA_POR_VOZ",
  INTRODUCE_LA_MATRICULA_POR_VOZ_PULSANDO_EN_EL_MICROFONO = "INTRODUCE_LA_MATRICULA_POR_VOZ_PULSANDO_EN_EL_MICROFONO",
  NO_TIENES_LOS_PERMISOS_NECESARIOS_PARA_UTILIZAR_ESTA_FUNCIONALIDAD = "NO_TIENES_LOS_PERMISOS_NECESARIOS_PARA_UTILIZAR_ESTA_FUNCIONALIDAD",
  NO_HA_SIDO_POSIBLE_RECONOCER_NINGUNA_PALABRA = "NO_HA_SIDO_POSIBLE_RECONOCER_NINGUNA_PALABRA",
  NO_SE_HA_PODIDO_ESCUCHAR_NINGUNA_VOZ = "NO_SE_HA_PODIDO_ESCUCHAR_NINGUNA_VOZ",
  DELTA_CAR = "DELTA_CAR",
  NO_EXISTE_NINGUN_VEHICULO_EMBARCADO = "NO_EXISTE_NINGUN_VEHICULO_EMBARCADO",
  ELIMINAR_VEHICULO_EMBARCADO = "ELIMINAR_VEHICULO_EMBARCADO",
  VEHICULO_EMBARCADO = "VEHICULO_EMBARCADO",
  VEHICULOS_EMBARCADOS = "VEHICULOS_EMBARCADOS",
  VEHICULO_EMBARCADO_MODIFICADO_CORRECTAMENTE = "VEHICULO_EMBARCADO_MODIFICADO_CORRECTAMENTE",
  VEHICULO_EMBARCADO_CREADO_CORRECTAMENTE = "VEHICULO_EMBARCADO_CREADO_CORRECTAMENTE",
  NUEVO_VEHICULO_EMBARCADO = "NUEVO_VEHICULO_EMBARCADO",
  TICKET_LINE = "TICKET_LINE",
  COMO_VAS_A_REALIZAR_LA_RUTA_DE_HOY = "COMO_VAS_A_REALIZAR_LA_RUTA_DE_HOY",
  PULSA_SOBRE_UNA_DE_LAS_OPCIONES = "PULSA_SOBRE_UNA_DE_LAS_OPCIONES",
  A_PIE = "A_PIE",
  CON_VEHICULO_EMBARCADO = "CON_VEHICULO_EMBARCADO",
  SELECCIONA_EL_VEHICULO_QUE_VAS_A_UTILIZAR = "SELECCIONA_EL_VEHICULO_QUE_VAS_A_UTILIZAR",
  POR_ULTIMO_NECESITAMOS_QUE_REGISTRES_TU_FIRMA = "POR_ULTIMO_NECESITAMOS_QUE_REGISTRES_TU_FIRMA",
  REGISTRAR_FIRMA = "REGISTRAR_FIRMA",
  FIRMA_REGISTRADA_CORRECTAMENTE = "FIRMA_REGISTRADA_CORRECTAMENTE",
  LEY_OBLIGATORIA = "LEY_OBLIGATORIA",
  RUTA_VEHICULO_EMBARCADO_EN_MARCHA = "RUTA_VEHICULO_EMBARCADO_EN_MARCHA",
  FIRMAR_Y_FINALIZAR_JORNADA = "FIRMAR_Y_FINALIZAR_JORNADA",
  CAMBIAR_DE_ESTADO = "CAMBIAR_DE_ESTADO",
  FICHERO_KML_OBLIGATORIO = "FICHERO_KML_OBLIGATORIO",
  POR_FAVOR_ANYADE_LA_CALLE_Y_EL_NUMERO = "POR_FAVOR_ANYADE_LA_CALLE_Y_EL_NUMERO",
  NO_ES_POSIBLE_SELECCIONAR_UNA_CALLE = "NO_ES_POSIBLE_SELECCIONAR_UNA_CALLE",
  POR_FAVOR_ANADE_LOS_DATOS_DEL_VEHICULO = "POR_FAVOR_ANADE_LOS_DATOS_DEL_VEHICULO",
  TRANSCRIPCION = "TRANSCRIPCION",
  ATENCION_VEHICULO_ESPECIAL = "ATENCION_VEHICULO_ESPECIAL",
  NO_PRESENTE = "NO_PRESENTE",
  TITULO_DATOS_CONDUCTOR_DENUNCIADO = "TITULO_DATOS_CONDUCTOR_DENUNCIADO",
  NIF_CONDUCTOR = "NIF_CONDUCTOR",
  NOMBRE_CONDUCTOR = "NOMBRE_CONDUCTOR",
  APELLIDOS_CONDUCTOR = "APELLIDOS_CONDUCTOR",
  DIRECCION_CONDUCTOR = "DIRECCION_CONDUCTOR",
  FIRMA_CONDUCTOR = "FIRMA_CONDUCTOR",
  TITULO_DATOS_CONDUCTOR_DENUNCIADO_OBLIGATORIO = "TITULO_DATOS_CONDUCTOR_DENUNCIADO_OBLIGATORIO",
  NIF_CONDUCTOR_OBLIGATORIO = "NIF_CONDUCTOR_OBLIGATORIO",
  TIPO_CONDUCTOR_OBLIGATORIO = "TIPO_CONDUCTOR_OBLIGATORIO",
  NOMBRE_CONDUCTOR_OBLIGATORIO = "NOMBRE_CONDUCTOR_OBLIGATORIO",
  APELLIDOS_CONDUCTOR_OBLIGATORIO = "APELLIDOS_CONDUCTOR_OBLIGATORIO",
  DIRECCION_CONDUCTOR_OBLIGATORIO = "DIRECCION_CONDUCTOR_OBLIGATORIO",
  FIRMA_CONDUCTOR_OBLIGATORIO = "FIRMA_CONDUCTOR_OBLIGATORIO",
  CODIGO_DE_APLICACION_C60 = "CODIGO_DE_APLICACION_C60",
  CODIGO_DE_TIPO_DE_FORMATO_C60 = "CODIGO_DE_TIPO_DE_FORMATO_C60",
  CODIGO_DE_PROVINCIA_C60 = "CODIGO_DE_PROVINCIA_C60",
  CODIGO_DE_MUNICIPIO_C60 = "CODIGO_DE_MUNICIPIO_C60",
  DIGITO_DE_CONTROL_C60 = "DIGITO_DE_CONTROL_C60",
  CAMPO_OBLIGATORIO = "CAMPO_OBLIGATORIO",
  ETIQUETA_CODIGO_DE_BARRAS_C60 = "ETIQUETA_CODIGO_DE_BARRAS_C60",
  NO_HA_SIDO_POSIBLE_COMPROBAR_EN_MOVIKTIK_LOS_TICKETS_DE_ESTA_MATRICULA = "NO_HA_SIDO_POSIBLE_COMPROBAR_EN_MOVIKTIK_LOS_TICKETS_DE_ESTA_MATRICULA",
  ESTA_EL_CONDUCTOR_PRESENTE = "ESTA_EL_CONDUCTOR_PRESENTE",
  FIRMA_DEL_DENUNCIADO = "FIRMA_DEL_DENUNCIADO",
  LINTERNA = "LINTERNA",
  ZOOM = "ZOOM",
  TRIBUTO_CONCEPTO_O_GRAVAMEN = "TRIBUTO_CONCEPTO_O_GRAVAMEN",
  PLAZO_DIAS_PAGO = "PLAZO_DIAS_PAGO",
  NO_EXISTEN_PLAZAS_PERDIDAS_PARA_EXPORTAR = "NO_EXISTEN_PLAZAS_PERDIDAS_PARA_EXPORTAR",
  INTENTAS_EXPORTAR_MAS_DE_10000_PLAZAS_PERDIDAS = "INTENTAS_EXPORTAR_MAS_DE_10000_PLAZAS_PERDIDAS",
  FLOWBIRD = "TR_FLOWBIRD",
  PARK_ID = "TR_PARK_ID",
  PARK_ID_OBLIGATORIO = "TR_PARK_ID_OBLIGATORIO",
  NO_REPEATABLE_PASSWORD = "NO_REPEATABLE_PASSWORD"
}

export default I18nKeys;