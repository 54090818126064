/* eslint-disable camelcase */
import { FunctionComponent } from "react";
import I18n from "../../commons/I18n/I18n";
import Report from "../../commons/model/Report";
import { CardBodyCheckbox } from "../../components-new/CardBodyCheckbox";
import { TableProps } from "../../components-new/typings";
import I18nKeys from "../../I18n/I18nKeys";
import DateFormatter from "../../utils/DateFormatter";
import ActiveLabel from "../label/ActiveLabel";
import Th from "../table/Th";

const ths = [
  {
    text: I18n.tr(I18nKeys.NUMERO_DE_DENUNCIA),
    columnName: "number",
  },
  {
    text: I18n.tr(I18nKeys.ESTADO),
    columnName: "report_state",
  },
  {
    text: I18n.tr(I18nKeys.ESTADO_DE_TRAMITE_DE_DENUNCIA),
    columnName: "report_sent_to_process_state",
  },
  {
    text: I18n.tr(I18nKeys.ESTADO_DE_ADVERACION),
    columnName: "ratified",
  },
  {
    text: I18n.tr(I18nKeys.FECHA_Y_HORA_DE_DENUNCIA),
    columnName: "date",
  },
  {
    text: I18n.tr(I18nKeys.MATRICULA),
    columnName: "vehicle",
  },
  {
    text: I18n.tr(I18nKeys.TIPO_DE_VEHICULO),
    columnName: "vehicle_type",
  },
  {
    text: I18n.tr(I18nKeys.MARCA),
    columnName: "vehicle_brand",
  },
  {
    text: I18n.tr(I18nKeys.MODELO),
    columnName: "vehicle_model",
  },
  {
    text: I18n.tr(I18nKeys.COLOR),
    columnName: "vehicle_color",
  },
  {
    text: I18n.tr(I18nKeys.ARTICULO_DE_LA_DENUNCIA),
    columnName: "law",
  },
  {
    text: I18n.tr(I18nKeys.CONTROLADOR),
    columnName: "controller",
  },
  {
    text: I18n.tr(I18nKeys.RATIFICADOR),
    columnName: "ratifier",
  },
  {
    text: I18n.tr(I18nKeys.NUMERO_DE_ANULACION),
    columnName: "cancellation_number",
  },
];

// eslint-disable-next-line import/prefer-default-export
export const ReportListTable: FunctionComponent<TableProps> = ( {data,
  sort,
  showCheckboxes,
  itemIdsSelected = [],
  itemIdsDisabled = [],
  itemIdsSelectedDisabled = [],
  onSort,
  onSelectItem,
  onSelectAllItems,
  onGoToItemDetails} ) => {
  const showChecks =
    !!showCheckboxes &&
    showCheckboxes &&
    onSelectItem &&
    onSelectAllItems &&
    onGoToItemDetails;
  const totalDisabledItems =
    itemIdsDisabled.length + itemIdsSelectedDisabled.length;
  const totalSelectedAndDisabledItems =
    totalDisabledItems + itemIdsSelected.length;
  const isAllDisabled = totalDisabledItems === data.length;
  const isAllSelected =
    itemIdsSelected.length > 0 && totalSelectedAndDisabledItems === data.length;
  const reportListTableTH = ths.map((th, index) => (
    <Th
      key={index}
      text={th.text}
      columnName={th.columnName}
      sort={sort}
      onSort={onSort}
    />
  ));

  return (
    <table className="table table-hover table-font-small">
      <thead>
        <tr>
          {showChecks
            ? (
              <CardBodyCheckbox
                checked={isAllSelected}
                disabled={isAllDisabled}
                onChange={onSelectAllItems!}
              />
            )
            : null}
          {reportListTableTH}
        </tr>
      </thead>
      <tfoot>
        <tr>
          {showChecks
            ? (
              <CardBodyCheckbox
                checked={isAllSelected}
                disabled={isAllDisabled}
                onChange={onSelectAllItems!}
              />
            )
            : null}
          {reportListTableTH}
        </tr>
      </tfoot>
      <tbody>
        {
          // eslint-disable-next-line multiline-ternary
          data
            ? data.map((report: Report) => {
              const {id,
                number,
                date,
                report_state,
                vehicle,
                law,
                workday,
                ratified,
                ratifier,
                report_sent_to_process_state} = report;
              const handleClick = onGoToItemDetails
                ? () => onGoToItemDetails(id)
                : undefined;
              const isSelected = itemIdsSelected.includes(id);
              const isDisabled = itemIdsDisabled.includes(id);
              const isDisabledSelected = itemIdsSelectedDisabled.includes(id);

              return (
                <tr key={id} onClick={handleClick} className={"tr-cursor"}>
                  {showChecks
                    ? (
                      <CardBodyCheckbox
                        checked={isSelected || isDisabledSelected}
                        disabled={isDisabled || isDisabledSelected}
                        onChange={() => onSelectItem!(id)}
                      />
                    )
                    : null}
                  <td>{number}</td>
                  <td>{report_state ? report_state.name : "-"}</td>
                  <td>
                    {report_sent_to_process_state
                      ? report_sent_to_process_state.name
                      : "-"}
                  </td>
                  <td>
                    <ActiveLabel
                      text={
                        ratified
                          ? I18n.tr(I18nKeys.ADVERADA)
                          : I18n.tr(I18nKeys.PENDIENTE)
                      }
                      active={ratified}
                    />
                  </td>
                  <td>{DateFormatter.formatDatetime(date)}</td>
                  <td>{vehicle ? vehicle.license_plate : "-"}</td>
                  <td>
                    {vehicle && vehicle.vehicle_type
                      ? vehicle.vehicle_type.name
                      : "-"}
                  </td>
                  <td>
                    {vehicle &&
                      vehicle.vehicle_model &&
                      vehicle.vehicle_model.vehicle_brand
                      ? vehicle.vehicle_model.vehicle_brand.name
                      : "-"}
                  </td>
                  <td>
                    {vehicle && vehicle.vehicle_model
                      ? vehicle.vehicle_model.name
                      : "-"}
                  </td>
                  <td>
                    {vehicle && vehicle.vehicle_color
                      ? vehicle.vehicle_color.name
                      : "-"}
                  </td>
                  <td>{law ? law.screen_text : "-"}</td>
                  <td>{workday && workday.user ? workday.user.name : "-"}</td>
                  <td>{ratifier ? ratifier.name : ""}</td>
                  <td>{report ? report.cancellation_number : ""}</td>
                </tr>
              );
            } )
            : null
        }
      </tbody>
    </table>
  );
};
