import { CSSProperties, useEffect, useState } from "react";

import { connect } from "react-redux";

import { ClipLoader } from "react-spinners";

import I18nKeys from "../../I18n/I18nKeys";

import Alert from "../../base/alerts/Alert";

import I18n from "../../commons/I18n/I18n";

import User, { UserData } from "../../commons/model/User";

import TaskUserEdit from "../../commons/ws/user/TaskUserEdit";

import ScreenCard from "../../components/screen/ScreenCard";

import { AppIcon } from "../../config/Config";

import { ROUTE_HOME, ROUTE_PROFILE } from "../../routing/Routes";

import { changeUserPassword } from "../../utils/AuthManager";

import { executeItemTask } from "../../utils/FormUtils";

import EditPasswordModal, {EditPasswordModalData} from "../admin/users/formdetail/EditPasswordModal";

import CurrentUserReducer from "../../redux/CurrentUserReducer";

import TaskUserCurrentDetail from "../../commons/ws/user/TaskUserCurrentDetail";

import { goToRoute } from "../../utils/Router";

const mapStateToPropsCurrentUser = CurrentUserReducer.autoMapToProps();

type Props = typeof mapStateToPropsCurrentUser;

const Profile: React.FC<Props> = ( { currentUser }: Props) => {
  const [profile, setProfile] = useState<User | null>();
  const [error, setError] = useState<User | null>( {
    name: "",

    username: "",

    last_name: "",
  } as unknown as User);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [disableForm, setDisableForm] = useState<boolean>(false);

  useEffect(() => {
    setProfile(currentUser);
  }, [currentUser]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (value.length <= 0) {
      setError( {
        ...(error as unknown as User),

        [name]: I18n.tr(I18nKeys.CAMPO_OBLIGATORIO),
      } );

      setDisableForm(true);
    } else {
      const newError = {
        ...error,

        [name]: "",
      };

      setError(newError as unknown as User);

      setDisableForm(
        newError.name !== "" ||
          newError.username !== "" ||
          newError.last_name !== "",
      );
    }

    setProfile( {
      ...(profile as unknown as User),

      [name]: value,
    } );
  };
  const handleCancelClickHome = () => {
    goToRoute(ROUTE_HOME);
  };
  const noop = () => {
    console.log("fill empty function");
  };
  const onChangePassword = (values: EditPasswordModalData): void => {
    if (currentUser!.id) {
      changeUserPassword(values.password, currentUser!.id);

      Alert.error(I18n.tr(I18nKeys.CONTRASENA_CAMBIADA_CORRECTAMENTE));
    } else
      Alert.error(I18n.tr(I18nKeys.NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA));

    setShowModal(false);
  };
  const onSubmit = (e): void => {
    if (currentUser!.id) {
      executeItemTask(
        new TaskUserEdit(currentUser!.id, profile as unknown as UserData),

        () => {
          new TaskUserCurrentDetail().execute();
          Alert.success(I18n.tr(I18nKeys.USUARIO_MODIFICADO_CORRECTAMENTE));
        },
      );
    }

    e.preventDefault();
  };

  return (
    <ScreenCard
      breadcrumbs={[
        {
          name: I18n.tr(I18nKeys.EDITAR_PERFIL),

          url: ROUTE_PROFILE,

          icon: AppIcon.USER,
        },
      ]}
    >
      <div className="card">
        {profile == null || error == null
          ? (
            <div style={style}>
              <div className={"align-middle text-center"}>
                <ClipLoader size={"50px"} color={"#FBB907"} loading={true} />
              </div>
            </div>
          )
          : (
            <>
              <div className="header">
                <div className="row">
                  <div className="col-sm-6">
                    <h2>{I18n.tr(I18nKeys.EDITAR_PERFIL)}</h2>
                  </div>
                </div>
              </div>

              <div className="body">
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <div
                          className={`form-line ${
                            error.username.length > 0 && "focused error"
                          }`}
                        >
                          <label className="main-label">
                            {I18n.tr(I18nKeys.NOMBRE_DE_USUARIO)}
                          </label>

                          <input
                            className="form-control"
                            name="username"
                            value={profile.username}
                            onChange={handleOnChange}
                          />
                        </div>

                        <label className="error">{error.username}</label>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <div
                          className={`form-line ${
                            error.name.length > 0 && "focused error"
                          }`}
                        >
                          <label className="main-label">
                            {I18n.tr(I18nKeys.NOMBRE)}
                          </label>

                          <input
                            className="form-control"
                            name="name"
                            value={profile.name}
                            onChange={handleOnChange}
                          />
                        </div>

                        <label className="error">{error.name}</label>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <div
                          className={`form-line ${
                            error.last_name.length > 0 && "focused error"
                          }`}
                        >
                          <label className="main-label">
                            {I18n.tr(I18nKeys.APELLIDO)}
                          </label>

                          <input
                            className="form-control"
                            name="lastName"
                            value={profile.last_name}
                            onChange={handleOnChange}
                          />
                        </div>

                        <label className="error">{error.last_name}</label>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <div>
                          <label className="main-label">
                            {I18n.tr(I18nKeys.COLOR)}
                          </label>

                          <input
                            type="color"
                            className="form-control"
                            name="color"
                            value={profile.color}
                            onChange={handleOnChange}
                          />
                        </div>

                        <label className="error"></label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <div>
                          <button
                            type="button"
                            className="btn waves-effect btn-lg btn-primary btn-block"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            {I18n.tr(I18nKeys.CAMBIAR_CONTRASENA)}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row p-t-20">
                    <div className="col-sm-4 col-md-6 col-lg-8"></div>

                    <div className="col-sm-4 col-md-3 col-lg-2">
                      <button
                        className="btn waves-effect btn-lg btn-default btn-block"
                        onClick={handleCancelClickHome}
                        type="button"
                      >
                      Cancelar
                      </button>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                      <button
                        type="submit"
                        className="btn btn-lg btn-block btn-primary waves-effect form-btn"
                        disabled={disableForm}
                      >
                      Guardar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
      </div>

      <EditPasswordModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={onChangePassword}
        forceChange={false}
        errorPassword={""}
        onPasswordChange={noop}
      />
    </ScreenCard>
  );
};

export default connect(mapStateToPropsCurrentUser)(Profile);

const style: CSSProperties = {
  position: "absolute",

  top: 0,

  bottom: 0,

  left: 0,

  right: 0,

  backgroundColor: "rgba(0, 0, 0, 0.3)",

  display: "flex",

  alignItems: "center",

  justifyContent: "center",

  zIndex: 100,

  borderRadius: 10,
};
